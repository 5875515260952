import React from 'react'

export default function Footer1() {
return (
<div>
  <div class="container-fluid backkk bottomar">
    <div class="row backkk border-bottom">
      <section>
        <div className='container-fluid m-3'>
          <div className='row'>
            <div className='col-md-12'>
              <div class="d-flex justify-content-around ">
                <div class='d-flex align-items-center'>
                  <div class=" textdecoration "> <a href="https://www.facebook.com/beangateitsolutions?mibextid=ZbWKwL" class="me-4 text-reset"> <i class="fab fa-facebook-f "></i>
                    </a> <a href="https://twitter.com/beangate_it" class="me-4 text-reset"> <i class="fab fa-twitter "></i> </a> <a href="#"
                      class="me-4 text-reset"> <i class="fab fa-google "></i> </a> <a href="#" class="me-4 text-reset">
                      <i class="fab fa-instagram "></i> </a> <a href="#" class="me-4 text-reset"> <i
                        class="fab fa-linkedin "></i> </a> </div>
                </div>
                <div className=''>
                  <div className='d-flex '><span className='marginrightbtn'><input type="email" class="form-control1 "
                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" /></span> <button
                      type="button" class="btn marginrightbtnyello">Click Here !</button> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row my-3 py-5 text-white border-bottom">
    <div class="col-md-4 pr-2">
        <div class="mb-3 text-white textdecoration">
          <h6 class="text-uppercase fw-bold mb-4 px-3">Contact</h6>
          <p className='px-3'><i class="fas fa-home me-3 text-white text-center"></i><a href="https://goo.gl/maps/vQ6FPLyTFYpiZgdd6">MIG-50
              Sector-B, Sonagiri, Piplani,<p><i class="pl-5 ml-3 marlf "></i><a href="https://goo.gl/maps/vQ6FPLyTFYpiZgdd6">Bhopal, (MP) Pin-462022</a></p></a></p>
          
          <p className='px-3'> <i class="fas fa-envelope me-3 text-white"></i><a href="mailto:beangateitsolutions@gmail.com">
              beangateitsolutions@gmail.com</a> </p>
          <p className='px-3'><i class="fas fa-phone me-3 text-white"></i><a href="tel:+91-7471112020">+91-7471112020, </a> <a href="tel:+91-9752740090">9752740090</a>
          </p>
          <p className='px-3'><i class="fas fa-print me-3 text-white"></i><a href="tel:+91-9752740090">+0755-3100296</a></p>
        </div>
      </div>
      <div class="col-md-2">
        <div class=" mb-4 textdecoration">
          <h6 class="text-uppercase fw-bold mb-4 px-3"> Useful Links </h6>
          <p className='px-3'> <a href="About" class="text-reset">About</a> </p>
          <p className='px-3'> <a href="Service" class="text-reset">Service</a> </p>
          <p className='px-3'> <a href="/" class="text-reset">Portfolio</a> </p>
          <p className='px-3'> <a href="/" class="text-reset">Training</a> </p>
          
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-4 textdecoration">
          <h6 class="text-uppercase fw-bold mb-4 px-3"> Site Map </h6>
          <p className='px-3'> <a href="https://academy.beangates.com/" target='blank' class="text-reset">Career</a> </p>
          <p className='px-3'> <a href="/" class="text-reset">FAQ</a> </p>
          <p className='px-3'> <a href="/" class="text-reset">Privacy & policy</a> </p>
          <p className='px-3'> <a href="/" class="text-reset">Terms & Conditions</a> </p>
          
        </div>
      </div>
      <div class="col-md-4">
        <div class=" mx-auto mb-4">
        <div class=" text-center bottomlogo "><img src="assets/img/Logo-Beangate-15.png" alt="" /> </div>
          <p class="px-3 mt-3"> Beangate IT Solution is a leading technology company providing innovative software solutions and
            IT services to businesses worldwide.</p>
        </div>
      </div>

    </div>{/* style="background-color: rgba(0, 0, 0, 0.05);" */}
    <div class="row deepfooter">
      <div class="col-md-12">
        <div class="text-center pb-3"> © 2023 all rights reserved by <a href="https://beangates.com/" class="text-reset">Beangate IT Solutions ||</a> Designed & Developed With &#129505; </div>
      </div>
    </div>
  </div>
</div>
)
}