import React, { useEffect } from 'react'
import "../../index.css"
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./trainning.css"



export default function Trainning() {
    useEffect(() => {

    }, [])

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplayspeed: 500,
        infinite: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    initialSlide: 3,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            }
        ]


    };

    return (
        <div>
            <Helmet>
                <title>Expert IT Training Services | Beangate IT Solutions</title>
                <meta name="description" content="Unlock your IT potential with Beangate IT Solutions' top-notch training programs. Our industry-leading instructors deliver hands-on learning experiences in the latest technologies, empowering you for a thriving IT career. Enroll now to gain a competitive edge in the dynamic world of tech."/>
            </Helmet>
            <section class="trnbg mt-5 py-4 bgcolo">
                < div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <h1 className='text-dark'>Exclusive Coding Courses<br /> for New Age Spartans!</h1>
                            <p>Learn with industry experts and get a chance to work with top companies in India and abroad. <br />
                            </p><button class="btn btn-outline-warning bg-warning text-dark">Guaranteed Placement & Free Career
                                Guidance</button>

                            <div className='row mt-3'>
                                <div className='col-md-6 pt-2'>
                                    <div class="bdr text-white">
                                        <h3>Online Classes</h3>
                                        <p>Learn from the comfort of your own home</p>
                                        <div style={{ width: "100%", height: "6px", backgroundColor: "#fff" }}></div><br />
                                        <div class="d-flex">
                                            <div>
                                                <h5>Package start from</h5>
                                            </div>&nbsp;&nbsp; | &nbsp;&nbsp;<div class="text-center">
                                                <h5> 4 LPA To</h5>
                                                <h5>15 LPA</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-1'>
                                    <div class="bdr text-white pt-3 pb-2">
                                        <h3> 2 Classes Free </h3>
                                        <p>Claim your free classes helmed by industry experts</p>
                                        <div style={{ width: "100%", height: "6px", backgroundColor: "#fff" }}></div><br />
                                        <div class="d-flex">
                                            <div>
                                                <h5>Package start from</h5>
                                            </div>&nbsp;&nbsp; | &nbsp;&nbsp;<div class="text-center">
                                                <h5> 4 LPA To</h5>
                                                <h5>15 LPA</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div width={"40%"} className=' m-5 trainingimg'><img src="./hero-removebg-preview.png" width={"450px"} height={"450px"} alt="hero-background"/>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
            <div class="py-5" id="about-pagee">
                <h4 class="text-center">Immersive Learning Approach to Become a Coding Spartan Like No Other <br /></h4>
                <div class="m-3">
                    <div class="container">
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-stretch mb-3 mb-lg-0 ">
                                <div class="card bggg text-white"> <a href='ClientRegister'>
                                    <img class="card-img" src="icons/Learn Python.jpg"
                                        alt="Card" />
                                </a>
                                    <div class="card-img-overlay dispee">
                                        <p class="card-text ">
                                            <h3 className='text-center text-white'>Learn Python</h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch mb-3 mb-lg-0 ">
                                <div class="card bggg text-white"><a href='ClientRegister'>
                                    <img class="card-img" src="icons/Learn Java.jpg"
                                        alt="Card" />
                                </a>
                                    <div class="card-img-overlay dispee">
                                        <p class="card-text ">
                                            <h3 className='text-center text-white'>Learn Java</h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch mb-3  mb-lg-0 ">
                                <div class="card bggg text-white"> <a href='ClientRegister'>
                                    <img class="card-img" src="icons/Learn c++.jpg"
                                        alt="Card" />
                                </a>
                                    <div class="card-img-overlay dispee">
                                        <p class="card-text ">
                                            <h3 className='text-center text-white'>Learn C++</h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch  mb-lg-0 ">
                                <div class="card bggg text-white">
                                    <a href='ClientRegister'>
                                        <img class="card-img" src="icons/Learn Full stack.jpg"
                                            alt="Card" />
                                    </a>

                                    <div class="card-img-overlay dispee">
                                        <p class="card-text ">
                                            <h3 className='text-center text-white'>Learn Full stack</h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch  mt-3 mb-lg-0 ">
                                <div class="card bggg text-white">
                                    <a href='ClientRegister'>
                                        <img class="card-img" src="icons/Learn Web Development.jpg"
                                            alt="Card" />
                                    </a>

                                    <div class="card-img-overlay dispee">
                                        <p class="card-text text-white">
                                            <h3 className='text-center'>Learn Web Development</h3>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch  mt-3 mb-lg-0 ">
                                <div class="card bggg text-white">
                                    <a href='ClientRegister'>
                                        <img class="card-img" src="icons/Learn Digital Marketing.jpg"
                                            alt="Card" />
                                    </a>

                                    <div class="card-img-overlay dispee">
                                        <p class="card-text text-white ">
                                            <h3 className='text-center'> Learn Digital Marketing</h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch  mt-3 mb-lg-0 ">
                                <div class="card bggg text-white">
                                    <a href='ClientRegister'>
                                        <img class="card-img" src="icons/Learn SEO.jpg"
                                            alt="Card" />
                                    </a>

                                    <div class="card-img-overlay dispee">
                                        <p class="card-text text-white ">
                                            <h3 className='text-center'>  Learn SEO</h3>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3  d-flex align-items-stretch  mt-3 mb-lg-0 ">
                                <div class="card bggg text-white">
                                    <a href='ClientRegister'>
                                        <img class="card-img" src="icons/Learn Graphic Designing.jpg"
                                            alt="Card" />
                                    </a>
                                    <div class="card-img-overlay dispee">
                                        <p class="card-text text-white ">
                                            <h3 className='text-center'>  Learn Graphic Designing </h3>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center my-3 "><button class="btn btn-outline-warning m-1">Join Your Free Classes
                        </button>&nbsp;<button class="btn btn-outline-warning m-1">Call us at  9752740090</button></div>
                    </div>
                </div>
            </div>


            <div className='container '>
                <div class="rndcontainer p-4" id="about-page">
                    <div class="text-center p-3">
                        <h4>Dive into our Immersive Program and Secure a One-Way Ticket to Dream Jobs at the
                            <br />Hottest, Happening Companies!</h4>
                        <div className='container'>

                            <Slider{...settings}>
                                <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="./trainingimg/training1.jpg"
                                    alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Positive overall experience with Beangates IT Company; their professionalism, attention to detail, and commitment to customer satisfaction, fostering a collaborative and comfortable working environment.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-4" style={{ width: "18rem", marginRight: "6px", marginLeft: "6px" }}><img
                                    class="card-img-top" src="./trainingimg/training2.jpg" alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Beangates IT Company caters to diverse client IT requirements with a wide range of services, including software development, IT consulting, web development, mobile app development, cloud computing, cybersecurity, and more.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="./trainingimg/training3.jpg"
                                    alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Beangates IT Company showcases expertise in the IT field, with a qualified and experienced team delivering effective solutions through their deep understanding of modern technologies and industry best practices.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="./trainingimg/training1.jpg"
                                    alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Beangates IT Company excels in responsive communication, addressing inquiries promptly, providing timely updates, and offering valuable suggestions to ensure a satisfying customer experience.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-4" style={{ width: "18rem", marginRight: "6px", marginLeft: "6px" }}><img
                                    class="card-img-top" src="./trainingimg/training3.jpg" alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Beangates IT Company consistently meets project deadlines, delivers promised solutions, and exceeds client expectations, fostering trust and confidence in their ability to handle complex IT projects.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-4 text-center" style={{ width: "18rem" }}><img class="card-img-top"
                                    src="./trainingimg/training1.jpg" alt="Card cap" />
                                    <div class="card-body cardsizetraining">
                                        <p class="card-text textal">Beangates IT Company prioritizes security, implementing robust measures to protect client data and ensure compliance. They are dedicated to maintaining confidentiality, integrity, and safeguarding sensitive information.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div class="text-center p-3 mt-4"><button class="btn btn-outline-warning">Join Your Free Classes</button></div>
                </div>
            </div>
            <div class="m-5">
                <div class="container text-center" style={{
                    backgroundColor: "#081924", border: "1px", color: " #fff",
                    borderRadius: "25px"
                }}>
                    <div class="row py-3">
                        <div className='col-md-1 offset-0'></div>
                        <div class="col-md-2" style={{ borderRight: "2px", color: "#fff" }}><strong>100% </strong><br />
                            <p>students placed   </p>
                        </div>
                        <div class="col-md-2" style={{ borderRight: "2px", color: "#fff" }}><strong>6 LPA</strong><br />
                            <p>Average CTC  </p>
                        </div>
                        <div class="col-md-2" style={{ borderRight: "2px", color: "#fff" }}><strong>15 LPA</strong><br />
                            <p>Highest CTC </p>
                        </div>
                        <div class="col-md-2" style={{ borderRight: "2px", color: "#fff" }}><strong>Total</strong><br />
                            <p> 30 Companies</p>
                        </div>
                        <div class="col-md-2" style={{ borderRight: "2px", color: "#fff" }}><strong>Hike </strong><br />
                            <p> Average 30% Salary </p>
                        </div>

                    </div>
                </div>
                <div class="text-center my-3 "><button class="btn btn-outline-warning m-1">Join Your Free Classes
                </button>&nbsp;<button class="btn btn-outline-warning m-1">Call us at  9752740090</button></div>
            </div>


            <section class="m-4">
                <div class="text-center">
                    <h4>Beangate is Your Gateway to Making Websites Like Amazon, Netflix, 	and LinkedIn Among Many Others
                    </h4>
                    <p>Land Your Dream Job with Our Proven Programs</p>
                </div>

            </section>

            <section class="container text-center  mt-3 mb-5" id="about-page" style={{
                border: "1px solid black", color: "#00424"
                , borderRadius: "5px"
            }}>
                <h1 className='text-dark'> Career Power-Up: Unleash the Exclusivity! Get Ready for a Personalized Career Plan that'll Catapult You into Professional Superstardom
                </h1>
                <div className='container mb-5'>

                    <Slider{...settings}>
                        <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="././trainingimg/training4.jpg"
                            alt="Card cap" />
                            <div class="card-body cardsizetraining">
                                <p class="card-text textal">Beangate IT Solutions offers a career power-up with opportunities for growth and advancement in the IT industry.</p>
                            </div>
                        </div>
                        <div class="card col-lg-4" style={{ width: "18rem", marginRight: "6px", marginLeft: "6px" }}><img
                            class="card-img-top" src="././trainingimg/training5.jpg" alt="Card cap" />
                            <div class="card-body cardsizetraining">
                                <p class="card-text textal">Beangate IT Solutions provides a remarkable career power-up, offering valuable opportunities for growth and advancement in the IT industry.</p>
                            </div>
                        </div>
                        <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="./trainingimg/training6.jpg"
                            alt="Card cap" />
                            <div class="card-body cardsizetraining">
                                <p class="card-text textal">Unleash your career potential with Beangate IT Solutions' transformative Career Power-Up program, offering comprehensive resources and support for accelerated growth in the IT industry.</p>
                            </div>
                        </div>
                        <div class="card col-lg-4" style={{ width: "18rem" }}><img class="card-img-top" src="././trainingimg/training1.jpg"
                            alt="Card cap" />
                            <div class="card-body cardsizetraining">
                                <p class="card-text textal">Beangate IT Solutions' Career Power-Up training provides a transformative opportunity to enhance skills and accelerate career growth in the IT industry.</p>
                            </div>
                        </div>

                    </Slider>
                </div>
                <div class="container mr-3 mt-2 mb-2"><button class="accordion">Career Guidance with Experts </button>
                    <div class="panel">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div class="container mr-3 mt-2 mb-2"><button class="accordion">Mock Interview Sessions</button>
                    <div class="panel">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div class="container mr-3 mt-2 mb-2"><button class="accordion">Detailed Interview Preparation :-</button>
                    <div class="panel">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="container mr-3 mt-2 mb-2"><button class="accordion">Unlock Scholarships</button>
                            <div class="panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="container mr-3 mt-2 mb-2"><button class="accordion">Wave Goodbye to Program Fees</button>
                            <div class="panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="container mr-3 mt-2 mb-2"><button class="accordion">Embrace Our Epic Job Guarantee!
                        </button>
                            <div class="panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="container mr-3 mt-2 mb-2"><button class="accordion">2 Classes Free
                        </button>
                            <div class="panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container mr-3 mt-2 mb-2"><button class="accordion">No Fee Required (EMI as low as 6K*)
                </button>
                    <div class="panel">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div class="container mr-3 mt-2 mb-2"><button class="accordion">Job Guarantee !</button>
                    <div class="panel">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </section>
            <div class="m-5">
                <div class="container text-center text-white p-3 " style={{ backgroundColor: "#081924", borderRadius: "45px" }}>
                    <h3>Live Project Training & Experience </h3>
                    <p> Become an Experienced Professional While Still Undergoing Training with Us!
                    </p>
                    <div class="text-center"><button class="btn btn-outline-warning">Join Your Free Classes </button></div>
                </div>
            </div>



        </div>
    )
}