import { Helmet } from 'react-helmet';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./contactus.css"

export default function ContactUs() {


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1m3yw4k', 'template_qhrp30w', form.current, 'AdqhwMg3c4TG-1sFZ')
            .then((result) => {
                alert("Email Sent Successfully")
            }, (error) => {
                alert("Email failed !")
            });
    };


    return (
        <div>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Get in Touch with Beangate IT Solutions - Your Bridge to Innovative Software Solutions. Have questions, inquiries, or collaboration ideas? Our team is here to assist you. Reach out today to start a conversation and explore how our expertise can drive your business forward." />
            </Helmet>
            <div className='contactmain'>
                <div className='container-fluid mt-5'>
                    <section class=" mt-5 backimgcontact " id="">
                        <div class="jumbotrons m-0 ">
                            <h2 className='mt-5'>Contact Beangate</h2>
                            <p> Redefine Possibilities Beyond Imagination{/* <br></br> improve the way we serve you. */}</p>
                        </div>
                    </section>
                </div>
                <section>
                    <div className='container-fluid mt-5 mb-2'>
                        <div div className='row'>
                            <div className='col-md-12'>
                                <div class="d-flex justify-content-center "> <a href='' className='contactbtn'>Click me</a> <a
                                    href='' className='contactbtn'>Click me</a> </div>
                            </div>
                        </div>
                    </div>
                    <section class="container border " id="contact-page">
                        <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
                            chat...</h4>
                        </header>
                        <div class="row ">
                            <div class="col-sm-6 contact-text" id="contact-text">
                                <p className='px-4'><br></br>Let’s Create a Better World! <br></br> <br></br> Regarded as the
                                    best IT Company in India, Beangate extends its support to empowering your businesses with
                                    transformative solutions, enabling remarkable advancements and driving success at every
                                    stage of the journey. </p>
                                <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br> +91-7471112020<br></br>
                                    +91-9752740090<br></br> +0755-3100296<br></br> <b>Email: </b><br></br>
                                    beangateitsolutions@gmail.com </p>
                            </div>
                            <div class="col-sm-6" id="contact-form">
                                <form ref={form} onSubmit={sendEmail} action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                                    <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" name="user_name" class="form-control" placeholder="Name" />
                                    </div>
                                    <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email" name="user_email" class="form-control" placeholder="Email" />
                                    </div>
                                    <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30" type="comment" class="form-control" placeholder="Comment" name="message"></textarea>
                                    </div>
                                    <button type="reset" class="btn silver-button"><i class="fa fa-trash"></i> Reset</button>
                                    <button type="submit" name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
                <section>
                    <div className='container-fluid mt-5 mb-5'>
                        <div className='row'>
                            <div className='col-md-12'> </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}