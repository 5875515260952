import { Helmet } from 'react-helmet';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./style.css"

export default function ClientRegister() {

const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yzzhu3o', 'template_4oins1g', form.current, 'AdqhwMg3c4TG-1sFZ')
    .then((result) => {
        alert (" Sent Successfully")
     }, (error) => {
       alert ("Error.. !")
     });
  };


    return (
        <div>
            <Helmet>
                <title>Register with Beangate IT Solutions | Streamline Your Experience</title>
                <meta name="description" content="Join Beangate IT Solutions by completing our easy client registration process. Access exclusive benefits, personalized services, and stay informed about our latest offerings. Register today and experience seamless engagement with a leading IT solutions provider." />
            </Helmet>
          {/* ref={form} onSubmit={sendEmail} ... form tag se related h */}

           <div class="container-fluid register mt-4">
                <div className='container mt-5'>
                <form  action='https://script.google.com/macros/s/AKfycbywOeKjMW01nVBiVloYZkJs9rR-ZO13lnAzbCILQw2vvUp3UV66NAXEAjAchNa0iCHvkQ/exec' method='POST'>
                    <div class="row pdform" >
                        <div class="col-md-4 register-left mt-4">
                            <img src="/Images/beanlogo.png" alt="" />
                            <h3>Welcome</h3>
                            <h3>To</h3>
                            <div class="d-flex flex-column align-items-center text-center">
                                <div class="mt-5">
                                    <h4>Beangate IT SoluTions</h4>
                                    <a class="font-size-xsm" href='/' >www.beangates.com </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 register-right' >
                        
                            <h3>Client Registration</h3>
                            <div class="row  register-form " >
                                <div class="col-md-4">
                                    <div class="form-group p-2">
                                        <input type="text" class="form-control" placeholder="Full Name *"  name="first_name" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group p-2">
                                        <input type="text" class="form-control" placeholder="Last Name *"  name="last_name" />
                                    </div>
                                </div>
                                <div class="col-md-4">

                                    <div class="form-group p-2">
                                        <input type="text" minlength="10" maxlength="10" name="YourPhone" class="form-control"
                                            placeholder="Your Phone *" />
                                    </div>
                                
                                </div>

                            </div>
                            <div className='row register-form '>
                                <div className='form-group col-md-4 '>
                                    <div class="form-group p-2">
                                        <input type="email" class="form-control" placeholder="Your Email *"  name="YourEmail"/>
                                    </div>
                                </div>
                                <div className='form-group col-md-4 '>
                                <div class="form-group p-2">
                                        <select id="inputState" class="form-control" name="CourseDetail">
                                        <option selected className=''>Select Project</option>
                                        <option>Web Development</option>
                                        <option>App Development</option>
                                    </select>
                                    </div> 
                                </div>
                                <div className='form-group col-md-4'>
                                <div class="form-group p-2">
                                        <select id="inputState" class="form-control" name="Country">
                                        <option selected className=''>Select Country</option>
                                        <option>India</option>
                                    </select>
                                    </div> 
                                </div>
                            </div>
                            <div class="row register-form m-0">
                            
                                <div class="form-group col-md-3 mb-3">
                                    <select id="inputState" class="form-control" name="State">
                                        <option selected>Select state/UT</option>
                                        <option>Madhya Pradesh</option>
                                        <option>Utter Pradesh</option>
                                        <option>Maharastra</option>
                                        <option>Gujrat</option>
                                        <option>Chhatisgarh</option>
                                        <option>Goa</option>
                                        <option>Delhi</option>
                                        <option>Hariyana</option>
                                        <option>Jammu & Kashmir</option>
                                        <option>Himachal Pradesh</option>
                                        <option>Andhra Pradesh</option>
                                        <option>Manipur</option>
                                        <option>Sikkim</option>
                                        <option>Tamil Nadu</option>
                                        <option>Meghalaya</option>
                                        <option>Arunachal Pradesh</option>
                                        <option>Assam</option>
                                        <option>Jharkhand</option>
                                        <option>Mizoram</option>
                                        <option>Telangana</option>
                                        <option>Tripura</option>
                                        <option>Nagaland</option>
                                        <option>Karnataka</option>
                                        <option>Bihar</option>
                                        <option>Punjab</option>
                                        <option>Odisha</option>
                                        <option>Uttarakhand</option>
                                        <option>Rajasthan</option>
                                        <option>West Bengal</option>
                                        <option>Andaman and Nicobar Islands</option>
                                        <option>Dadra and Nagar Haveli and Daman & Diu</option>
                                        <option>Lakshadweep</option>
                                        <option>Puducherry</option>
                                        <option>Ladakh</option>
                                        <option>Chandigarh</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="District *"  name="District"/>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="City *"  name="City" />
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3 ">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Pin Code*"   name="PinCode"/>
                                    </div>
                                </div>
                                {/* <a type="submit" name="submit" class="" value="send" >Register</a> */}
                                <button type="submit" name="submit" class="btn btn btnRegister text-white " value="send"><i class="fa fa-send-o   text-white"></i> Submit</button>
                            </div>
                        </div>
                    
                    </div>
                </form>
                </div>
            </div>
           </div>
       



    )
}
