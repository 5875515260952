

import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import Header from "./components/Header";
import About from './components/About';
import Home from './pages/Home';
import Ourservices from './components/Ourservices';
import Footer1 from './components/Footer1';
import ContactUs from './components/ContactUs';
import Product from './pages/product/Product';
import Blogs from './pages/Blogs/Blogs';
import Development from './components/Development';
import Wordpress from './components/Wordpress';
import Webhosting from './Webhosting';
import Androiddevelopment from './components/Androiddevelopment';
import SoftwareDevelopment from './components/SoftwareDevelopment';
import Applicationdevelopment from './components/Applicationdevelopment';
import Devops from './components/Devops';
import Digitalmarketing from './components/Digitalmarketing';
import Trainning from './pages/trainning/Trainning';
import ClientRegister from './pages/ClientRegister';
import Founder from './components/Founder';
import StudentRegister from './Client Registration/StudentRegister';


// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

export default function App() {

  let location = window.location.href;
  if (location === "https://beangates.com/StudentRegisterPage" || location === "http://localhost:3000/StudentRegisterPage") {

    return (
      <BrowserRouter>
        <Routes>
          <Route path="StudentRegisterPage" element={<StudentRegister />} />
        </Routes>
      </BrowserRouter>
    )
  }
  else {
    return (
      <div>

        <Header />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/About' element={<About />} />
            <Route path='/Service' element={<Ourservices />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Products" element={<Product />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/Webdevelopment" element={<Development />} />
            <Route path="/Wordpress" element={<Wordpress />} />
            <Route path="/Webhosting" element={<Webhosting />} />
            <Route path="/androiddevelopment" element={<Androiddevelopment />} />
            <Route path="/Softwaredevelopment" element={<SoftwareDevelopment />} />
            <Route path="/Applicationdevelopment" element={<Applicationdevelopment />} />
            <Route path="/Devops" element={<Devops />} />
            <Route path="/Digitalmarketing" element={<Digitalmarketing />} />
            <Route path="/Trainning" element={<Trainning />} />
            <Route path="ClientRegisterPage" element={<ClientRegister />} />
            <Route path="/Founder" element={<Founder />} />


          </Routes>
        </BrowserRouter>

        <Footer1 />

        {AddLibrary(
          'assets/js/main.js'
        )
        }

      </div>
    )
  }
}