import React from 'react';
import Slider from "react-slick";
import "./Product.css";
const Product = () => {
const settings = {
dots: true,
infinite: true,
autoplay: true,
speed: 2000,
autoplaySpeed: 2000,
slidesToShow: 1,
slidesToScroll: 1
};
return (
<>
  <section class="productbackimgservice" id="">

    <div class="jumbotron topppma mt-5">
      <h2 className='float-left-product'>
        Every Products <br></br>
      </h2>
      <br></br>
      <h3 className='float-left-product2'>
        of Beangates is design for safty
      </h3>

    </div>
  </section>
  <section className='container'>
    <div className="section-title text-white light">
      <div className='pb-0 mt-5'>
        <h2>Our Products</h2>
      </div>
      <div className='lineh2'> </div>{/* <p>Unlock Opportunities and Revolutionize Productivity with No.1 IT Company in
        India.</p>*/}
    </div>
    <div class="row mt-3">
      <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur sint eaque officiis minima dolore,
          velit maxime tenetur! Eius, ea sapiente sequi iure aperiam odio accusamus laudantium minus eum tempora quas.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur sint eaque officiis minima dolore,
          velit maxime tenetur! Eius, ea sapiente sequi iure aperiam odio accusamus laudantium minus eum tempora quas.
        </p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur sint eaque officiis minima dolore,
          velit maxime tenetur! Eius, ea sapiente sequi iure aperiam odio accusamus laudantium minus eum tempora quas.
        </p>
      </div>
      <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
        <div className='container'>
          <Slider{...settings}>
            <div> <img src="whybeangate.jpg" alt="" class="img-fluid" /> </div>
            <div> <img src="build1.jpg" alt="" class="img-fluid" /> </div>
            <div> <img src="box7-1.jpg" alt="" class="img-fluid" /> </div>
            </Slider>
        </div>
      </div>
    </div>
  </section>
  <section id="tabs" class="tabs mt-5">
    <div class="container" data-aos="fade-up">
      <ul class="nav nav-tabs row d-flex">
        <li class="nav-item col-3"> <a class="nav-link active show shadow p-3" data-bs-toggle="tab" data-bs-target="#tab-1"> <i
              class="ri-gps-line"></i>
            <h4 class="d-none d-lg-block">Modi sit est dela pireda nest</h4>
          </a> </li>
        <li class="nav-item col-3"> <a class="nav-link shadow p-3" data-bs-toggle="tab" data-bs-target="#tab-2"> <i
              class="ri-body-scan-line"></i>
            <h4 class="d-none d-lg-block">Unde praesenti mara setra le</h4>
          </a> </li>
        <li class="nav-item col-3"> <a class="nav-link shadow p-3" data-bs-toggle="tab" data-bs-target="#tab-3"> <i
              class="ri-sun-line"></i>
            <h4 class="d-none d-lg-block">Pariatur explica nitro dela</h4>
          </a> </li>
        <li class="nav-item col-3"> <a class="nav-link shadow p-3" data-bs-toggle="tab" data-bs-target="#tab-4"> <i
              class="ri-store-line"></i>
            <h4 class="d-none d-lg-block">Nostrum qui dile node setra</h4>
          </a> </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active show" id="tab-1">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3>
              <p class="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <ul>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                  pariatur.</li>
              </ul>
              <p> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200"> <img
                src="build1.jpg" alt="" class="img-fluid" /> </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-2">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>Neque exercitationem debitis soluta quos debitis quo mollitia officia est</h3>
              <p> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p>
              <p class="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <ul>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="ri-check-double-line"></i> Provident mollitia neque rerum asperiores dolores quos qui a.
                  Ipsum neque dolor voluptate nisi sed.</li>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                  pariatur.</li>
              </ul>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center"> <img src="box7-2.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-3">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>Voluptatibus commodi ut accusamus ea repudiandae ut autem dolor ut assumenda</h3>
              <p> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p>
              <ul>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="ri-check-double-line"></i> Provident mollitia neque rerum asperiores dolores quos qui a.
                  Ipsum neque dolor voluptate nisi sed.</li>
              </ul>
              <p class="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center"> <img src="build1.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-4">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>Omnis fugiat ea explicabo sunt dolorum asperiores sequi inventore rerum</h3>
              <p> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p>
              <p class="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <ul>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                  pariatur.</li>
              </ul>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center"> <img src="box7-2.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <section id="clients" class="clients">
      <div className="section-title text-white light">
        <div className='pb-0'>
          <h2>Our Live Product</h2>
        </div>
        <div className='lineh2'> </div>{/* <p>Unlock Opportunities and Revolutionize Productivity with No.1 IT Company
          in India.</p>*/}
      </div>
      <div class="container">
        <div class="row no-gutters clients-wrap clearfix wow fadeInUp">
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo"> <img src="client1.jpg" class="img-fluid" alt="" /> </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section>
    <div className='container mt-5 mb-5'>
      <div class="row ">
        <div class="col-md-6">
          <div class="card"> <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp" class="card-img-top"
              alt="Hollywood Sign on The Hill" />
            <div class="card-body">
              <h5 class="card-title text-dark ">Card title</h5>
              <p class="card-text text-dark"> This is a longer card with supporting text below as a natural lead-in to
                additional content. This content is a little bit longer. </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card"> <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp" class="card-img-top"
              alt="Palm Springs Road" />
            <div class="card-body">
              <h5 class="card-title text-dark">Card title</h5>
              <p class="card-text text-dark"> This is a longer card with supporting text below as a natural lead-in to
                additional content. This content is a little bit longer. </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card"> <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/043.webp" class="card-img-top"
              alt="Los Angeles Skyscrapers" />
            <div class="card-body">
              <h5 class="card-title text-dark">Card title</h5>
              <p class="card-text text-dark">This is a longer card with supporting text below as a natural lead-in to
                additional content.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card"> <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/044.webp" class="card-img-top"
              alt="Skyscrapers" />
            <div class="card-body">
              <h5 class="card-title text-dark">Card title</h5>
              <p class="card-text text-dark"> This is a longer card with supporting text below as a natural lead-in to
                additional content. This content is a little bit longer. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
);
}

export default Product;