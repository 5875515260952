import React from 'react'
import "./Team.css"

export default function Team() {
return (
<div>
    <div id="team" class="over1">
        <div class="container mt-5 pt-1 over1 px-4">
            <div className="section-title text-white light">
                <div className='pb-0'>
                    <h2>Our Team</h2>
                </div>
                <div className='lineh55 mt-1 mb-3'> </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="row">
                        
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/nawaz.png" class="img-fluid" />
                                    <h3>Nawaz </h3>
                                    <p>Front-End Developer & Digital Marketar</p>
                                </div>
                                <div class="team-back"> Nawaz is a skilled and creative professional with expertise in
                                    HTML, CSS, and JavaScript. He loves crafting seamless user experiences.</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/Aalok.jpg" class="img-fluid" />
                                    <h3>Alok Singhanyia </h3>
                                    <p>Full Stack Developer</p>
                                </div>
                                <div class="team-back"> Alok Singhanyia is an innovative full stack developer with expertise in
                                    front-end and back-end development and other creative web solutions. </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/sachin.jpg" class="img-fluid" />
                                    <h3>Sachin</h3>
                                    <p>Mern Stack Developer</p>
                                </div>
                                <div class="team-back"> Sachin is a creative graphic designer with a keen eye for
                                    aesthetics and passion for crafting visually stunning designs.</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/sandeep1.jpg" class="img-fluid" />
                                    <h3>Sandeep </h3>
                                    <p>Front End Developer</p>
                                </div>
                                <div class="team-back"> Sandeep is a skilled and creative professional with expertise in
                                    HTML, CSS, and JavaScript. He loves crafting seamless user experiences.</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/shrsti1.jpg" class="img-fluid" />
                                    <h3>Shrsti</h3>
                                    <p>Front End Developer</p>
                                </div>
                                <div class="team-back"> Shrsti is a skilled front-end developer. She combines
                                    creativity and coding expertise to deliver exceptional user experiences.</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/Aman.jpg" class="img-fluid" />
                                    <h3>Aman</h3>
                                    <p>Front End Developer</p>
                                </div>
                                <div class="team-back"> Aman is a sensational to designs , interactive user interfaces through HTML, CSS, and JavaScript mastery.
                                 Dedicated to enhancing user experiences by blending aesthetics with functionality. Passionate about staying at the forefront of web development trends to deliver dynamic and responsive websites.</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/Priya.jpg" class="img-fluid" />
                                    <h3>Priya</h3>
                                    <p>Receptionist</p>
                                </div>
                                <div class="team-back"> Skilled in effective communication, persuasion, and building
                                    strong client relationships, She is an experienced telecaller. </div>
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div class="our-team-main">
                                <div class="team-front"> <img src="./Team/khushboo.jpg" class="img-fluid" />
                                    <h3>Khushbu</h3>
                                    <p>Front End Developer</p>
                                </div>
                                <div class="team-back"> Khushbu is a skilled front-end developer. She combines
                                    creativity and coding expertise to deliver exceptional user experiences. </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</div>
)
}