import React from 'react';

const Service = () => {
return (
<section id="services" className="ser services mt-3">
  <div className="container">
    <div className="section-title text-white light">
      <div className='pb-1'>
        <h2>Efficiency Redefined</h2>
      </div>
      <div className='lineh1 mb-2'> </div>
      <p className='text-center'>Unlock Opportunities and Revolutionize Productivity with No.1 IT Company in India</p>
    </div>
    <div className="row">
      <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img" src="icons/build1.jpg" alt="Card image" />
          <div class="card-img-overlay disp">
            <p class="card-text ">
            <h3 className='text-center text-white'>Build Experience</h3>
            <h6 className='text-white hov text-center'>Design engaging user interfaces and boost your bottom line.</h6>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img" src="icons/generate.jpg" alt="Card image" />
          <div class="card-img-overlay disp">
            <p class="card-text text-white">
            <h3 className='text-center'>Generate Leads</h3>
            <h6 className='text-white text-center'>Generate high-quality leads that convert into sales.</h6>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img" src="icons/SurfCloud.jpg" alt="Card image" />
          <div class="card-img-overlay disp">
            <p class="card-text text-white ">
            <h3 className='text-center'>Surf Clouds</h3>
            <h6 className='text-white text-center'>Optimize your cloud operations with our DevOps Services to boost
              efficiency.</h6>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
);
}

export default Service;