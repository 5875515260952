import { Helmet } from 'react-helmet';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./css/style.css"

export default function StudentRegister() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_yzzhu3o', 'template_4oins1g', form.current, 'AdqhwMg3c4TG-1sFZ')
            .then((result) => {
                alert(" Sent Successfully")
            }, (error) => {
                alert("Error.. !")
            });
    };



    return (
        <div className='over'>
            <Helmet>
                <title>Join Beangate IT Solutions as a JavaScript Trainer | Empower the Next Generation of Developers</title>
                <meta name="description" content="Are you a JavaScript expert with a passion for teaching? Join Beangate IT Solutions as a JavaScript Trainer and help shape the future of web development. Apply now to inspire and educate the next generation of developers." />
            </Helmet>

            <div class="container-fluid register ">
                <form ref={form} onSubmit={sendEmail}>
                    <div class="row" >
                        <div class="col-md-3 register-left mt-5">
                            <img src="assets/img/favicon.png" alt="" />
                            <h3>Welcome</h3>
                            <h3>To</h3>
                            <div class="d-flex flex-column align-items-center text-center">
                                <div class="">
                                    <h4>Beangate IT SoluTions</h4>
                                    <a class="font-size-xsm" href='beangates.com'>www.beangates.com </a>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-9 register-right'>

                            <h3>JavaScript Trainer Registration Form</h3>
                            <div class="row  register-form " >
                                <div class="col-md-6">
                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Name</label>
                                        <input type="text" class="form-control" placeholder="Enter Your Full Name *" name="full_name" />
                                    </div>
                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Email</label>
                                        <input type="email" class="form-control" placeholder="Enter Your Email Address*" name="YourEmail" />
                                    </div>
                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Qualification</label>
                                        <input type="text" class="form-control" placeholder="Enter Your Qualification*" name="YourQualification" />
                                    </div>
                                    {/* <div class="form-group p-2">
                                        <input type="text" class="form-control" placeholder="Qualification *" name="Qualification" />
                                    </div> */}



                                </div>
                                <div class="col-md-6">
                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Phone</label>
                                        <input type="number" minlength="10" maxlength="10" name="YourPhone" class="form-control text-muted"
                                            placeholder="Enter Your Contact Number*" />
                                    </div>

                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Experiance</label>
                                        <input type="text" name="YourExperiance" class="form-control text-muted"
                                            placeholder="Enter Your Experiance Level*" />
                                    </div>
                                    <div class="form-group p-2">
                                        <label class="custom-file-label" for="customFile">Expertise
                                        </label>
                                        <input type="text" name="YourExpertise" class="form-control text-muted"
                                            placeholder="Enter Your Expertise Here*" />
                                    </div>

                                    {/* <div class="form-group p-2">
                                        <select id="inputState" class="form-control" name="CourseDetail">
                                            <option selected className=''>Select Courses</option>
                                            <option>Java</option>
                                            <option>Python</option>
                                            <option>C++</option>
                                            <option>React</option>
                                            <option>Angular</option>
                                            <option>PHP</option>
                                            <option>C#</option>
                                            <option>Cyber Security</option>
                                            <option>Ethical Hacking</option>
                                            <option>Mern Stack</option>
                                            <option>Mern Stack</option>
                                            <option>Data Science</option>
                                            <option>Machine Learning</option>
                                            <option>Full stack development</option>
                                            <option>SEO</option>
                                            <option>Google Ads</option>
                                            <option>Social Media Marketing</option>

                                        </select>
                                    </div> */}
                                    {/* <form className='form-group p-2'>
                                        <div class="custom-file mt-1">
                                                <label class="custom-file-label" for="customFile">Upload Your CV</label>
                                            <input type="file" class="custom-file-input" id="customFile" name='resume'/>
                                        </div>
                                        
                                    </form> */}

                                </div>
                                <button type="submit" name="submit" class="btn btnRegister text-white" value="send"><i class="fa fa-send-o"></i> Submit</button>
                            </div>
                            {/* <div class="row register-form m-0">
                                <div class="col-md-3 ">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Country*" name="Country" />
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <select id="inputState" class="form-control" name="State">
                                        <option selected>Select state/UT</option>
                                        <option>Madhya Pradesh</option>
                                        <option>Utter Pradesh</option>
                                        <option>Maharastra</option>
                                        <option>Gujrat</option>
                                        <option>Chhatisgarh</option>
                                        <option>Goa</option>
                                        <option>Delhi</option>
                                        <option>Hariyana</option>
                                        <option>Jammu & Kashmir</option>
                                        <option>Himachal Pradesh</option>
                                        <option>Andhra Pradesh</option>
                                        <option>Manipur</option>
                                        <option>Sikkim</option>
                                        <option>Tamil Nadu</option>
                                        <option>Meghalaya</option>
                                        <option>Arunachal Pradesh</option>
                                        <option>Assam</option>
                                        <option>Jharkhand</option>
                                        <option>Mizoram</option>
                                        <option>Telangana</option>
                                        <option>Tripura</option>
                                        <option>Nagaland</option>
                                        <option>Karnataka</option>
                                        <option>Bihar</option>
                                        <option>Punjab</option>
                                        <option>Odisha</option>
                                        <option>Uttarakhand</option>
                                        <option>Rajasthan</option>
                                        <option>West Bengal</option>
                                        <option>Andaman and Nicobar Islands</option>
                                        <option>Dadra and Nagar Haveli and Daman & Diu</option>
                                        <option>Lakshadweep</option>
                                        <option>Puducherry</option>
                                        <option>Ladakh</option>
                                        <option>Chandigarh</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="District *" name="District" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="City *" name="City" />
                                    </div>
                                </div>
                                <button type="submit" name="submit" class="btn btn btnRegister text-white" value="send"><i class="fa fa-send-o"></i> Submit</button>
                            </div> */}
                        </div>

                    </div>
                </form>
            </div>
        </div>



    )
}
