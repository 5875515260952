import React from 'react'
import { Helmet } from 'react-helmet';
import Team from './Team'


export default function Founder() {
    return (
        <div>
            <Helmet>
                <title>About Founder & CEO</title>
                <meta name="description" content="Meet the Visionary Founder of Beangate IT Solutions : Mr. Raj Verma. Discover the driving force behind our success, Mr. Raj Verma's expertise, and their relentless dedication to shaping a brighter technological future. Learn about the journey that led to the creation of our innovative software solutions and the inspiration that fuels our commitment to excellence." />
            </Helmet>
            <section className='box-3 mt-5'>
                <div className='container '>
                    <div className='row mb-5'>
                        <div className='col-md-12 '>
                            <div class=" text-center mt-4">
                                <h2>Founder & CEO</h2>
                            </div>
                        </div>

                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-1'></div>
                        <div className='col-md-3 p-2'>
                            <div class="card cardsh">
                                <div class="ownerimg card-b ">
                                    <img src='./Team/Bhagirath.jpg'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-6'>
                            <div class="card cardsh cardshheight">
                                <div class="card-body  p-4">
                                    <h4 className='pt-1 mt-2'> Bhagirath Singroli</h4>
                                    <p>Founder & Chairman</p>
                                    <div className='pt-3 pb-3 text-justify owntext'>Driven by relentless pursuit of excellence, Bhagirath
                                        Singroli serves as the visionary founder and chairman of Beangate IT Solutions.
                                        The accomplished leader spearheads transformative initiatives and empowers
                                        individuals on their journey of growth and innovation.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                    </div>

                    <div className='row mt-2'>
                        <div className='col-md-1'></div>
                        <div className='col-md-3 p-2'>
                            <div class="card cardsh ">
                                <div class="ownerimg card-b ">
                                    <img src='./Team/Pankaj.jpg'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-6'>
                            <div class="card cardsh cardshheight">
                                <div class="card-body p-4">
                                    <h4 className='p-1 mt-2'>Pankaj Dubey</h4>
                                    <p> Co-founder & CEO </p>
                                    <div className='pt-3 pb-3 text-justify owntext'> With a deep passion for technology and a keen business
                                        acumen, Pankaj leads as the co-founder and CEO of Beangate IT Solutions. His
                                        leadership drives the company towards continuous growth and a steadfast
                                        commitment to technological excellence.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                    </div>

                </div>
            </section >



        </div >
    )
}
