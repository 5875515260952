import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';
import "../components/about.css"
import "./contactus.css"

export default function ContactUs() {


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1m3yw4k', 'template_qhrp30w', form.current, 'AdqhwMg3c4TG-1sFZ')
            .then((result) => {
                alert("Email Sent Successfully")
            }, (error) => {
                alert("Email failed !")
            });
    };

    return (
        <div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Unveiling Our Journey in Transforming Ideas into Powerful Software Solutions. Learn about Beangate IT Solutions passion for innovation, commitment to excellence, and the dedicated team driving our success. Explore how we've been shaping industries through technology, one solution at a time." />
            </Helmet>

            <main className='marginabouttop'>
                <section class="container-fluid mt-5 py-5 " id="splash-page">
                    <div class="jumbotron float-left-about mt-5">
                        <h2 className='pt-5 mt-5'> Engineering a Future of </h2><br></br> <h2 className='marlef'> New Possibilities</h2>


                    </div>
                </section>


                <section class="container-fluid " id="about-page">
                    <header class="row">
                        <div class="col-xs-12 text-center mt-4">
                            <h2>About Beangate</h2>

                        </div>
                    </header>
                    <div class="row border p-4">
                        <div class="col-sm-5 imggg">
                            <img src="icons/aboutusmain.jpg" class="img-responsive" alt='aboutusmain' />
                            <br />
                            {/* <button class="btn purple-button" data-toggle="modal" data-target="#cvModal"><i
                                class="fa fa-eye"></i> View CV</button>
                            <a href="#">
                                <button class="btn btn-default silver-button"><i class="fa fa-download"></i> Download CV</button>
                            </a> */}
                        </div>

                        <div class="col-sm-7 " id="bio">
                            {/* <h3 class="text-left mt-3">Interactive Development with a Digital marketing</h3> */}
                            <p className=' text-justify px-3 pt-5'>
                                Beangate IT Solutions is the no.1 IT Company in India enabling businesses around the globe to harness a gamut of information technologies as an invaluable advantage. Helmed by a team of unmatched visionaries and their passion to address customer needs, Beangate has been able to create success stories in diverse verticals. We have revolutionized the industry by innovating existing technologies, developing futuristic products, and by delivering solutions that aim to explore new possibilities beyond dimensions. In the process, we’ve had priceless opportunities to collaborate with various industry leaders leading us to crack the most difficult codes of our business ecosystem.
                            </p>
                            <p className=' text-justify px-3'>The legacy of Beangate IT Solutions is built on the fact that simplicity is the key to some of the most complex problems in businesses nowadays. This is why we build scalable solutions for our clients in the agilest way possible.
                            </p>
                            <p className=' text-justify px-3'>Operating from the heart of Bhopal in Madhya Pradesh, and offices in other parts of the country and abroad, we are a Multi-national company. Our smart local delivery models allow us to remain approachable to clients in any part of the world. We work round-the-clock. We deliver unmatched solutions with a focus on high ROI and unrivaled success.
                            </p>

                        </div>

                    </div>
                </section>

                <div>

                    <div class="modal fade" id="cvModal" role="dialog">
                        <div class="modal-dialog ">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h3 class="modal-title">Bianca Allyn Morris</h3>
                                </div>
                                <div class="modal-body">
                                    <h4>EDUCATION</h4>
                                    <h5><strong>Wellesley College, Wellesley, MA</strong></h5>
                                    <p>Candidate for BA degree in Cinema and Media Studies, June 2017.</p>
                                    <p>Cross-registered at Massachusetts Institute of Technology's Department of Comparative Media
                                        Studies, Spring 2016.</p>
                                    <p><em>Achievements:</em> Awarded 2015 Millennium Campus Network Fellowship. 2016 Albright
                                        Fellowship Finalist. 2014 Library Research Award in First Year Writing.</p>
                                    <h4>WORK EXPERIENCE</h4>
                                    <h5><strong>Wellesley College, Office of Communications and Public Affairs (Spring 2016 -
                                        c.)</strong></h5>
                                    <p><em>Digital Content Assistant:</em> Maintain the Wellesley College website by copy-editing
                                        text, conducting student body polls and research, editing photo/video in Adobe suite, and
                                        more.</p>
                                    <h5><strong>Boston Digital Productions (Spring 2016)</strong></h5>
                                    <p><em>Video Production Intern:</em> Worked as production assistant to two-time Emmy winning
                                        director on the set of commercials, testimonials and other non-fiction videos. Developed and
                                        implemented online marketing strategies. Conducted research for and acquired content from
                                        photo/video archives for the full-length documentary “The Strange Name Movie.”</p>
                                    <h5><strong>Re:Route [Previously Wellesley Initiative for Sustainable Computing]
                                        (2014-2015)</strong></h5>
                                    <p><em>Student Technician:</em> Refurbished Mac and PC computers, handled donation requests and
                                        fulfilled orders. Maintained the Re:Route page on the Wellesley College website.</p>
                                    <h4>SKILLS</h4>
                                    <p><strong>Digital Media Production:</strong> Proficient in Final Cut Pro, HTML, CSS, Github.
                                        Familiar with DSLRs, Adobe Creative Suite, Javascript, Python, Drupal, Wordpress, FTP File
                                        Transfer, and various web-hosting/domain services.</p>
                                    <p><strong>Productivity Tools:</strong> Proficient with Microsoft Office (excluding Access),
                                        Asana, Google Calendar.</p>
                                    <p><strong>Communication:</strong> 2014 Tanner Conference Speaker, basic knowledge of Spanish &
                                        Japanese.</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default silver-button" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                {/* <section class="container-fluid portfolio-page" id="portfolio-page">
                    <header className='text-center portmartop mt-5'>
                        <h2 >Our History</h2>
                        <h4>Projects from 2015-2016</h4>
                        <hr>
                        </hr>
                    </header>
                    <div class="row">
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive " src="icons/growth.jpeg"></img>
                            <h4>Aldous Huxley Tribute Page (2016)</h4>
                            <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                            
                        </div>
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive " src="icons/growth.jpeg"></img>
                            <h4>SeedKit Data Explorer ( 2016)</h4>
                            <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive  " src="icons/growth.jpeg"></img>
                            <h4>Audiocracy ( 2016)</h4>
                           <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                        </div>
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive " src="icons/growth.jpeg"></img>
                            <h4>Bianca-Morris.com ( 2016)</h4>
                            <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive " src="icons/growth.jpeg"></img>
                            <h4>GenkiFLASH ( 2016)</h4>
                            <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                        </div>
                        <div class="col-sm-6 text-center">
                            <img class="img-responsive " src="icons/growth.jpeg"></img>
                            <h4>Nourish International E-Letter ( 2015)</h4>
                           <h6><em>Purpose:</em> To produce a 'data-driven interactive documentary' experience for CMS.338,
                                    a course I took with Professor Uricchio at MIT. </h6>
                            <p>HTML, CSS, Bootstrap </p>
                        </div>
                    </div>

                </section> */}


                <section class="container-fluid pb-5" id="contact-page">
                    <header>
                        <h2 className='text-center mt-5'>Contact US</h2>

                        <hr>
                        </hr>
                    </header>
                    <div class="row ">
                        <div class="col-sm-6 contact-text" id="contact-text">
                            <p className='px-4 mt-4'>Let’s Create a Better World!
                                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses with transformative solutions, enabling remarkable advancements and driving success at every stage of the journey.
                            </p>
                            <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                                +91-7471112020<br></br>
                                +91-9752740090<br></br>
                                +0755-3100296<br></br>
                                <b>Email: </b><br></br>
                                beangateitsolutions@gmail.com
                            </p>
                        </div>
                        <div class="col-sm-6" id="contact-form">
                            <form ref={form} onSubmit={sendEmail} action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" name="user_name" class="form-control" placeholder="Name" />
                                </div>
                                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email" name="user_email" class="form-control" placeholder="Email" />
                                </div>
                                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30" type="comment" class="form-control" placeholder="Comment" name="message"></textarea>
                                </div>
                                <button type="reset" class="btn silver-button"><i class="fa fa-trash"></i> Reset</button>
                                <button type="submit" name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
                            </form>
                        </div>
                    </div>
                </section>
            </main>




        </div>
    )
}

