import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import "./androiddev.css"

export default function Androiddevelopment() {
    return (
        <div>

            <div className=' ' id='devhero'>
                <div className='container-fluid mt-5'>
                    <section class=" mt-5 androiddevelopmentbanner " id="">
                        <div class="jumbotrons androiddevelopment m-0 ">
                            <h2 className='mt-5 mb-3'>Android Development </h2>
                            <p> Riding the Crest of Android Studio for Unmatched Android Development Solutions <br></br>Best
                                Android Development Company in India{/* <br></br> improve the way we serve you. */}</p>
                            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
                        </div>
                    </section>
                </div>
                <section>
                    <div className='container-fluid mt-5 p-5 devp'>
                        <div className='row mt-4 py-5'>
                            <div className='col-md-5 devh3'>
                                <h3 className='text-justify'>Android studio and Android Development Services </h3>
                            </div>
                            <div className='col-md-7 '>
                                <p className='test'> As the leading provider of Android development services in India, Beangate
                                    expands its range of offerings to cater to businesses in various industries, such as IT,
                                    software, real estate, logistics, hospitality, and Edtech, among others. We specialize in
                                    developing dynamic and secure Android studio applications that are highly adaptable and
                                    efficient. </p>{/* <p> We build websites that are agile, robust, and smart. Our
                            cybersecurity, eCommerce, and wordpress solutions along with website architecture,
                            maintenance, and custom CMS services are second to none. </p>*/}
                            </div>
                        </div>
                    </div>
                </section>




                <section className='bg-dev'>
                    <div className='container pt-5 pb-5 '>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <h3>Boost Your Business Growth </h3>
                                <h3>with Our Android Studio & Android Development Solutions</h3>
                            </div>
                            <div className='col-md-12 mt-5'>
                                <p className='text-center '>Beangate is powered by a fearless team of Android programming
                                    <br></br>Spartans ready to slay the competition, even Xerxes would be impressed! </p>
                            </div>
                        </div>





                        <div className='row pt-5'>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/nativeandroidappdevelopment.png'></img> </div>
                                <h6 className='text-center mb-4'> Native Android App Development </h6>
                                <p className='test'> Our team of skilled developers utilizes the power of Android Studio to
                                    create high-performance native applications that leverage the full potential of the Android
                                    platform </p>
                            </div>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/UXUI development.png'
                                    alt='UXUI development'></img> </div>
                                <h6 className='text-center mb-4'> User Interface (UI) and User Experience (UX) Design </h6>
                                <p className='test'> We understand the significance of delivering a visually appealing and
                                    intuitive user interface. Our UI/UX designers craft captivating designs that enhance user
                                    engagement and provide a seamless navigation experience. </p>
                            </div>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/Api integration.png' alt='Api integration'></img>
                                </div>
                                <h6 className='text-center mb-4'> API Integration</h6>
                                <p className='test'> We have extensive experience in integrating APIs and third-party services
                                    into Android Studio applications. Whether it's integrating social media platforms, payment
                                    gateways, or other data sources, we ensure seamless communication between your app and
                                    external services. </p>
                            </div>
                        </div>
                        <div className='row' id=''>
                            <div className='col-md-12 col-sm-6 text-center mt-4'>
                                <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
                            </div>
                        </div>
                    </div>
                </section>



                <section>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 pt-5 mt-3'> <img src='icons/androiddevelopmentleft.jpg'
                                alt='androiddevelopmentleft'></img> </div>
                            <div className='col-md-6 pt-5 devhjust '>
                                <h3 className='text-justify pt-1 '> Make Your Online Game Smart with State-of-the-art Android
                                    Development Company </h3>
                                <p> Beangate takes pride in creating customized android development solutions including: </p>
                                <ul className='lignh'>
                                    <li> <a href=''> Native Android App Development </a> </li>
                                    <li> <a href=''> User Interface (UI) and User </a> </li>
                                    <li> <a href=''> API Integration </a> </li>
                                    <li> <a href=''> Database Integration and Management </a> </li>
                                    <li> <a href=''> Performance Optimization </a> </li>
                                    <li> <a href='/Wordpress'> Cross-platform Development </a> </li>
                                    <li> <a href=''> Testing and Quality Assurance </a> </li>
                                    <li> <a href=''> App Store Submission Support </a> </li>{/* <li> <a href=''> Maintenance
                                </a> </li>
                            <li> <a href=''> Backend Solutions </a> </li>
                            <li> <a href=''> Frontend Solutions </a> </li>*/}<p className='mt-4 test'> As India's
                                        leading Android Studio and Android Development Company, we are committed to crafting
                                        bespoke android solutions that empower businesses to surpass their goals. We firmly
                                        believe that with our expertise and visionary approach, exceeding expectations becomes
                                        the new norm. </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-dev '>
                    <div className='container pt-5 pb-5 '>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <h3>Harness the Superpowers of Our Android Development <br></br> Process for Business Success
                                </h3>{/* <h3>development solutions</h3> */}
                            </div>
                            <div className='col-md-12 mt-5'>
                                <p className='text-center '> Led by a team of visionary Android development experts, Beangate
                                    understands the importance of meticulous attention to detail in delivering exceptional
                                    anroid development solutions. With years of experience and a portfolio of successful
                                    projects, we have mastered the art of handling projects of all sizes while maintaining a
                                    seamless development process from start to finish. No more! Take your business to new
                                    heights with our cutting-edge technologies </p>
                            </div>
                        </div>
                        <div className='row pt-5'>
                            <div className='col-md-4 iconsize2'>
                                <div className='text-center'> <img src='icons/discoveryandroid.png'
                                    alt='discoveryandroid'></img> </div>
                                <h5 className='text-center mb-4'>Discovery Phase</h5>
                                <p className='text-center'> Extensive research and analysis to ensure a solid foundation for the
                                    Android development process </p>
                            </div>
                            <div className='col-md-4 iconsize2'>
                                <div className='text-center'> <img src='icons/UXUi nterface.png' alt='UXUi nterface'></img>
                                </div>
                                <h5 className='text-center mb-4'> UI/UX Design</h5>
                                <p className='text-center'> Designing interfaces that are not only aesthetically pleasing but
                                    also easy to navigate </p>
                            </div>
                            <div className='col-md-4 iconsize2'>
                                <div className='text-center'> <img src='icons/developmentandroid.png'
                                    alt='developmentandroid'></img> </div>
                                <h5 className='text-center mb-4'> Development </h5>
                                <p className='text-center'> Industry best practices and coding standards to ensure clean and
                                    efficient code that is maintainable in the long run </p>
                            </div>
                        </div>
                        <div className='row' id=''>
                            <div className='col-md-12 col-sm-6 text-center mt-4'>
                                <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container mt-5 py-2 devp'>
                        <div className='row mt-4 py-5'>
                            <div className='col-md-5 devh4'>
                                <h4 className='text-justify'>Leverage the superpowers of our web design and web development
                                    process for the success of your business </h4>
                            </div>
                            <div className='col-md-7 '>
                                <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver
                                    spot on web solutions one must be detail-savvy. And, in a career spanning these many years
                                    and several products, we know exactly how to handle projects of various magnitudes while
                                    also ensuring seamless process throughout. </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container py-4 '>
                        <div className='row'>
                            <div className='col-md-1 col-sm-6 text-center'> </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">1</p>
                                <h4> Consultation </h4>
                                <p class="numbers__caption">Brainstorm ideas to set the direction</p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">2</p>
                                <h4> Adoption </h4>
                                <p class="numbers__caption">Choose a technology for the goals </p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light ">3</p>
                                <h4> Design </h4>
                                <p class="numbers__caption">Craft designs and give shapes to your visions </p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">4</p>
                                <h4>Develop </h4>
                                <p class="numbers__caption">Start coding to bring your product to life</p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">5</p>
                                <h4> Reach </h4>
                                <p class="numbers__caption">Launch the product to the market </p>
                            </div>
                            <div className='col-md-1 text-center'> </div>
                        </div>
                    </div>
                </section>
                <section className='bg-dev'>
                    <div className='container py-5 mt-4 '>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">70+</p>
                                <p class="numbers__caption">Product Designers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">40+</p>
                                <p class="numbers__caption">Frontend Developers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol ">80+</p>
                                <p class="numbers__caption">Backend Developers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">45+</p>
                                <p class="numbers__caption">Quality Assurance Specialists</p>
                            </div>
                        </div>
                        <div className='row py-4 mt-5'>
                            <div className='col-md-5  pb-2 whtimg '> <img src='whybeangatedeve.png'
                                alt='whybeangatedeve'></img> </div>
                            <div className='col-md-7 pt-5 devhjust p-4'>
                                <h3 className='text-justify pt-3 pb-4'> Why Choose Beangate as Your Android Development Company?
                                </h3>
                                <p className='mt-4 lh-lg test'> Attention all trailblazing appreneurs and visionary leaders
                                    commanding startups or mighty corporations! Fear not, for Beangate is here to be your
                                    ultimate ally in the realm of Android development. We transcend the ordinary, going beyond
                                    mere expertise to become your strategic partners in navigating the ever-evolving digital
                                    landscapes. With our collaboration, rest assured that your app will not only meet, but
                                    exceed all expectations, leaving both you and your users awe-inspired. Why settle for
                                    anything less than exceptional? Embrace the enchantment and unite with Beangate to ignite
                                    the spark of magic within your Android creations. Let's revolutionize the app world
                                    together! </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container-fluid'>
                        <div className='row pt-5'>
                            <div className='col-md-12 '>
                                <h2>Our Android Studio & Android Development Company </h2>
                            </div>
                            <div className='col-md-1'></div>
                            <div className='col-md-10 mt-3 '>
                                <p className='text-center p-0'>At the core of our organization, you'll find a handpicked group of exceptional android programming wizards who bring invaluable expertise to every phase of your product's evolution. Allow us to introduce our esteemed team of visionary
                                    talents: <br></br> </p>
                            </div>
                            <div className='col-md-1'></div>

                        </div>
                    </div>
                </section>
                
                <section className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loops={true}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                        <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/androiddeveloper.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Android Developer </h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/uxdesign.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">UX/UI Developer</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/frontend.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">QA/Testers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/backend.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Backend Developers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/projectmanager.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Project Managers</h5>
                                        <p class="card-text py-3 ">Some quick example text to build on the card title and make
                                            up the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/uxresearcher.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">UX Researchers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/databasespecialist.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3"> Database Specialists</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/securityexpert.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3"> Security Experts</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </section>



                <section className='bg-dev'>
                    <div className='container-fluid pt-4'>
                        <div className='row pt-5'>
                            <h2 className='mb-3'> Frequently Asked Question </h2>
                            <div className='col-md-5 text-center mt-3'> <img src='whatquestion2.png'></img>
                            </div>
                            <div className='col-md-7 '>
                                <section id="why-us" class="why-us mt-5">
                                    <div class="container-fluid" data-aos="fade-up">
                                        <div class="row">
                                            <div
                                                class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                                                <div class="accordion-list">
                                                    <ul>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                                                            class="collapsed"><span>01</span>Q: What is Android development?
                                                            <i class="bx bx-chevron-down icon-show"></i><i
                                                                class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-1" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Android development refers to the process of creating
                                                                    mobile applications for devices running the Android
                                                                    operating system. It involves using Android programming
                                                                    languages, such as Java or Kotlin, along with tools like
                                                                    Android Studio and the Android SDK, to build and deploy
                                                                    Android applications. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                                                            class="collapsed"><span>02</span>Q: What is Android Studio?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-2" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Android Studio is the official integrated development
                                                                    environment (IDE) for Android app development. It provides a
                                                                    comprehensive set of tools, including a code editor,
                                                                    debugger, emulator, and layout designer, to streamline the
                                                                    Android development process. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                                                            class="collapsed"><span>03</span> Q: What is the Android SDK?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-3" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> The Android SDK (Software Development Kit) is a collection
                                                                    of libraries, tools, and resources that developers use to
                                                                    create Android applications. It provides APIs and frameworks
                                                                    for accessing device features, handling user interactions,
                                                                    and more, making it easier to build robust and feature-rich
                                                                    Android apps. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                                                            class="collapsed"><span>04</span> Q: How much does Android app
                                                            development cost? <i class="bx bx-chevron-down icon-show"></i><i
                                                                class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-4" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: The cost of Android app development can vary depending on
                                                                    various factors, such as the complexity of the app, the
                                                                    number of features, design requirements, and the development
                                                                    company's rates. It's best to discuss your specific project
                                                                    details with an Android development company to get an
                                                                    accurate cost estimate. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                                                            class="collapsed"><span>05</span>Q: What is the typical timeline
                                                            for Android app development? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-5" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: The timeline for Android app development depends on the
                                                                    project's scope and complexity. Simple apps with basic
                                                                    features can take a few weeks, while more complex apps with
                                                                    advanced functionality may take several months. A reputable
                                                                    Android development company can provide a project timeline
                                                                    based on your requirements. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                                                            class="collapsed"><span>06</span> Q: How do I choose the right
                                                            Android development company?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-6" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: When selecting an Android development company, consider
                                                                    their experience, portfolio, client reviews, expertise in
                                                                    Android app development, and the ability to meet project
                                                                    deadlines. It's also essential to discuss your project
                                                                    requirements, communication channels, and pricing before
                                                                    making a decision. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                                                            class="collapsed"><span>07</span> Q: Can you assist with
                                                            publishing my Android app on the Google Play Store? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-7" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Yes, a professional Android development company can guide
                                                                    you through the process of publishing your app on the Google
                                                                    Play Store. They will ensure that your app meets the
                                                                    necessary guidelines, handle the app submission process, and
                                                                    assist with any required optimizations for a successful
                                                                    launch. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                                                            class="collapsed"><span>08</span> Q: Do you provide ongoing
                                                            support and maintenance for Android apps?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-8" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Yes, many Android development companies offer
                                                                    post-development support and maintenance services. This
                                                                    includes addressing any bugs or issues that may arise,
                                                                    providing updates for compatibility with new Android
                                                                    versions, and offering technical assistance to ensure the
                                                                    smooth operation of your Android app. </p>
                                                            </div>
                                                        </li>{/* <li> <a data-bs-toggle="collapse"
                                                        data-bs-target="#accordion-list-9"
                                                        class="collapsed"><span>09</span> Q: Can you assist with website
                                                        hosting and deployment?<i
                                                            class="bx bx-chevron-down icon-show"></i><i
                                                            class="bx bx-chevron-up icon-close"></i></a>
                                                    <div id="accordion-list-9" class="collapse"
                                                        data-bs-parent=".accordion-list">
                                                        <p> A: Absolutely! We provide assistance with website hosting
                                                            and deployment, ensuring a smooth transition from
                                                            development to a live environment. </p>
                                                    </div>
                                                </li>
                                                <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                                                        class="collapsed"><span>10</span> Q: How do you ensure effective
                                                        communication throughout the project?<i
                                                            class="bx bx-chevron-down icon-show"></i><i
                                                            class="bx bx-chevron-up icon-close"></i></a>
                                                    <div id="accordion-list-10" class="collapse"
                                                        data-bs-parent=".accordion-list">
                                                        <p> A: We prioritize clear and timely communication, assigning
                                                            dedicated project managers and utilizing collaborative tools
                                                            to keep you informed and involved every step of the way.
                                                        </p>
                                                    </div>
                                                </li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                                                data-aos-delay="150">&nbsp;</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='contactmains'>
                    <section>
                        <section class="container " id="">
                            <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's
                                have a chat...</h4>
                            </header>
                            <div class="row ">
                                <div class="col-sm-6 contact-text" id="contact-text">
                                    <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
                                    <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br>
                                        <br></br> Regarded as the best IT Company in India, Beangate extends its support to
                                        empowering your businesses with transformative solutions, enabling remarkable
                                        advancements and driving success at every stage of the journey. </p>{/* <p
                                className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email:
                                </b><br></br> beangateITsolutions@gmail.com </p>*/}
                                </div>
                                <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
                                    <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                                        <div class="form-group p-2 "> <label for="name">Name</label> <input type="name"
                                            class="form-control" placeholder="Name" /> </div>
                                        <div class="form-group p-2 "> <label for="email">Email address</label> <input
                                            type="email" class="form-control" placeholder="Email" /> </div>
                                        <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5"
                                            cols="30" type="comment" class="form-control" placeholder="Comment"></textarea>
                                        </div><button type="reset" class="btn silver-button"><i class="fa fa-trash"></i>
                                            Reset</button> <button type="submit" name="submit" class="btn purple-button"
                                                value="send"><i class="fa fa-send-o"></i> Submit</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section>
                        <div className='container-fluid mt-5 mb-5'>
                            <div className='row'>
                                <div className='col-md-12'> </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </div>
    )
}