import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';



import "./applicationdevelopment.css"

export default function Applicationdevelopment() {
    return (
        <div>

            <div className=' ' id='devhero'>
                <div className='container-fluid mt-5'>
                    <section class=" mt-5 Applicationdevelopmentbanner " id="">
                        <div class="jumbotrons Applicationdevelopmentjumbo m-0 ">
                            <h2 className='mt-5 mb-3'>Application Development </h2>
                            <p> Take Your Product to Your Audience’s Pocket with Mobile App Development Solutions <br></br>Top
                                Mobile App Development Company in India{/* <br></br> improve the way we serve you. */}</p>
                            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
                        </div>
                    </section>
                </div>
                <section>
                    <div className='container-fluid mt-5 p-5 devp'>
                        <div className='row mt-4 py-5'>
                            <div className='col-md-4 devh3'>
                                <h3 className='text-justify'>Mobile App Development Services </h3>
                            </div>
                            <div className='col-md-8 '>
                                <p> At Beangate, we offer top-notch mobile app development services that empower businesses to
                                    thrive in the digital landscape. Our team of experienced developers, designers, and
                                    strategists collaborate closely with clients to bring their app ideas to life. Whether it's
                                    developing a native iOS or Android app or creating a cross-platform solution, we leverage
                                    the latest technologies and industry best practices to deliver high-performance and
                                    user-friendly mobile applications. </p>{/* <p> We build websites that are agile, robust, and
                            smart. Our cybersecurity, eCommerce, and wordpress solutions along with website
                            architecture, maintenance, and custom CMS services are second to none. </p>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-dev'>
                    <div className='container pt-5 pb-5 '>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <h3>Experience the Magic of Beangate's Mobile App Development Solution </h3>{/* <h3>Web Design
                            and Web Development Solutions</h3> */}
                            </div>
                            <div className='col-md-12 mt-5'>
                                <p className='text-center '> Embark on a journey of unparalleled growth with Beangate, powered
                                    by a fearless battalion of mobile app development superheroes. <br></br> Our extraordinary
                                    team of experts is equipped with the knowledge and skills to create cutting-edge mobile
                                    applications <br></br> that will skyrocket your business to new heights of success.</p>
                            </div>
                        </div>
                        <div className='row pt-5'>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/nativemobiledev.png' alt='nativemobiledev'></img>
                                </div>
                                <h6 className='text-center mb-4'> Native Mobile App Development</h6>
                                <p className='test'> We leverage the native capabilities of each platform to deliver
                                    high-performance, feature-rich apps that provide seamless user experiences and optimal
                                    performance.</p>
                            </div>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/crossplateformmobiledev.png'
                                    alt='crossplateformmobiledev'></img> </div>
                                <h6 className='text-center mb-4'> Cross-Platform Mobile App Development </h6>
                                <p className='test'> With our cross-platform mobile app development services, we create apps
                                    that can run on multiple platforms, including iOS, Android, and more. </p>
                            </div>
                            <div className='col-md-4 iconsize'>
                                <div className='text-center'> <img src='icons/hybridmoniledev.png' alt='hybridmoniledev'></img>
                                </div>
                                <h6 className='text-center mb-4'> Hybrid Mobile App Development </h6>
                                <p className='test'> Our hybrid mobile app development services offer a cost-effective solution
                                    that combines the benefits of both native and web technologies. </p>
                            </div>
                        </div>
                        <div className='row' id=''>
                            <div className='col-md-12 col-sm-6 text-center mt-4'>
                                <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 pt-5 mt-3'> <img src='icons/applicationdevelopmentleft.jpg'
                                alt='applicationdevelopmentleft'></img> </div>
                            <div className='col-md-6 pt-5 devhjust '>
                                <h3 className='text-justify pt-1 '> Boost Your Game with Beangate's Genius Mobile App
                                    Development Solutions </h3>
                                <p> Beangate offers a wide range of solutions including:</p>
                                <ul className='lignh'>
                                    <li> <a href=''> Native Mobile App Development </a> </li>
                                    <li> <a href=''> Cross-Platform Mobile App Development: </a> </li>
                                    <li> <a href=''> Hybrid Mobile App Development </a> </li>
                                    <li> <a href=''> Mobile UI/UX Design </a> </li>
                                    <li> <a href=''> Mobile App Testing and Quality Assurance: </a> </li>
                                    <li> <a href='/Wordpress'> App Maintenance and Support </a> </li>
                                    <li> <a href=''> Mobile App Consulting and Strategy </a> </li>
                                    <li> <a href=''> App Store Optimization (ASO) </a> </li>
                                    <li> <a href=''> Mobile App Integration </a> </li>
                                    <li> <a href=''> Wearable App Development </a> </li>{/* <li> <a href=''> Frontend Solutions
                                </a> </li>*/}<p className='mt-4 test'> As India's trusted Mobile App Development
                                        Company, we are renowned for our collection of bespoke mobile app solutions that empower
                                        businesses. We believe that with our expertise and visionary approach, exceeding
                                        expectations becomes the new norm. </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section><section className='bg-dev '>
                    <div className='container mt-5 py-2 devp'>
                        <div className='row mt-4 py-5'>
                            <div className='col-md-5 devh4'>
                                <h4 className='text-justify'>Leverage the superpowers of our web design and web development
                                    process for the success of your business </h4>
                            </div>
                            <div className='col-md-7 '>
                                <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver
                                    spot on web solutions one must be detail-savvy. And, in a career spanning these many years
                                    and several products, we know exactly how to handle projects of various magnitudes while
                                    also ensuring seamless process throughout. </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container py-4 '>
                        <div className='row'>
                            <div className='col-md-1 col-sm-6 text-center'> </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">1</p>
                                <h4> Consultation </h4>
                                <p class="numbers__caption">Brainstorm ideas to set the direction</p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">2</p>
                                <h4> Adoption </h4>
                                <p class="numbers__caption">Choose a technology for the goals </p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light ">3</p>
                                <h4> Design </h4>
                                <p class="numbers__caption">Craft designs and give shapes to your visions </p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">4</p>
                                <h4>Develop </h4>
                                <p class="numbers__caption">Start coding to bring your product to life</p>
                            </div>
                            <div className='col-md-2 col-sm-6 text-center'>
                                <p class="numbers__score h2 text-muted-light">5</p>
                                <h4> Reach </h4>
                                <p class="numbers__caption">Launch the product to the market </p>
                            </div>
                            <div className='col-md-1 text-center'> </div>
                        </div>
                    </div>
                </section>
                <section className='bg-dev'>
                    <div className='container py-5 mt-4 '>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">70+</p>
                                <p class="numbers__caption">Product Designers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">40+</p>
                                <p class="numbers__caption">Frontend Developers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol ">80+</p>
                                <p class="numbers__caption">Backend Developers</p>
                            </div>
                            <div className='col-md-3 col-sm-6 text-center'>
                                <p class="numbers__score h2 numcol">45+</p>
                                <p class="numbers__caption">Quality Assurance Specialists</p>
                            </div>
                        </div>
                        <div className='row py-4 mt-5'>
                            <div className='col-md-5  pb-2 whtimg'> <img src='whybeangatedeve.png'></img> </div>
                            <div className='col-md-7 pt-5 devhjust p-4'>
                                <h3 className='text-justify pt-4 pb-4'> Why Choose Beangate as Your Mobile App Development
                                    Company? </h3>
                                <p className='mt-4 lh-lg test'> Choose Beangate for your mobile app development needs. With a
                                    skilled team, cutting-edge technologies, and a commitment to client satisfaction, we deliver
                                    exceptional mobile solutions that align with your business goals. Experience seamless
                                    communication, timely delivery, and unmatched support throughout the development process and
                                    beyond. Trust Beangate for mobile success that exceeds expectations. <br></br> </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section >
                    <div className='container'>
                        <div className='row pt-5'>
                            <div className='col-md-12 '>
                                <h2>Our Mobile App Development Company </h2>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <p className='text-center '>Meet our mobile app development dream team - a group of ingenious
                                    minds driven by innovation and passion. With diverse skills and unwavering dedication, they
                                    transform ideas into captivating and user-friendly apps. From coding maestros to design
                                    wizards, they collaborate seamlessly to deliver exceptional results. Trust our team to bring
                                    your vision to life with brilliance and a touch of magic. </p>
                            </div>

                        </div>
                    </div>
                </section>

                <section className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loops={true}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/androiddeveloper.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Mobile App Developers </h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/qaspecialist.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Quality Assurance (QA) Testers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/businessanalysts.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Business Analysts</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/devopsengineer.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">DevOps Engineers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/agilecoach.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Agile Coach</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/support&maintainence.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Support and Maintenance Team</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/securityexpert.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Security Experts</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/projectmanager.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Project Managers</h5>
                                        <p class="card-text py-3 ">Some quick example text to build on the card title and make
                                            up the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/uxresearcher.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">UI/UX Researchers</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=''>
                                <div class=" text-center">
                                    <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                                        src="icons/databasespecialist.jpg" alt="Card image cap" />
                                        <h5 class="card-title p-3">Database Specialists</h5>
                                        <p class="card-text py-3">Some quick example text to build on the card title and make up
                                            the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </section>



                <section className='bg-dev'>
                    <div className='container-fluid pt-4'>
                        <div className='row pt-5'>
                            <h2 className='mb-3'> Frequently Asked Question </h2>
                            <div className='col-md-5 text-center mt-4'> <img src='whatquestion2.png'></img> </div>
                            <div className='col-md-7 '>
                                <section id="why-us" class="why-us mt-5">
                                    <div class="container-fluid" data-aos="fade-up">
                                        <div class="row">
                                            <div
                                                class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                                                <div class="accordion-list">
                                                    <ul>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                                                            class="collapsed"><span>01</span>Q: How long does it take to
                                                            develop a mobile app? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-1" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> The development timeline depends on the complexity of the
                                                                    app, features required, and the platform(s) targeted. A
                                                                    simple app can be developed within a few weeks, while more
                                                                    complex projects may take several months. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                                                            class="collapsed"><span>02</span>Q: What platforms can you
                                                            develop mobile apps for?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-2" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: We specialize in developing apps for both iOS
                                                                    (iPhone/iPad) and Android platforms. Our team is skilled in
                                                                    native app development as well as cross-platform development
                                                                    frameworks. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                                                            class="collapsed"><span>03</span> Q: What is the cost of
                                                            developing a mobile app?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-3" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: The cost varies depending on factors like app complexity,
                                                                    features, design, and development hours involved. We offer
                                                                    customized pricing based on individual project requirements.
                                                                    Contact us for a detailed quote. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                                                            class="collapsed"><span>04</span> Q: Do you provide ongoing
                                                            support and maintenance after the app is developed? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-4" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Yes, we provide post-development support and maintenance
                                                                    services to ensure your app remains updated, secure, and
                                                                    compatible with the latest mobile platforms. We offer
                                                                    various support packages tailored to your needs. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                                                            class="collapsed"><span>05</span>Q: Can you help with app store
                                                            submission and approvals?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-5" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Absolutely! We assist our clients in preparing their apps
                                                                    for submission to app stores, including Apple App Store and
                                                                    Google Play Store. We guide you through the process,
                                                                    ensuring compliance with guidelines and increasing the
                                                                    chances of approval. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                                                            class="collapsed"><span>06</span> Q: How do you ensure the
                                                            security of my app and user data? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-6" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Security is a top priority for us. We implement
                                                                    industry-standard security measures, including secure data
                                                                    transmission, encryption, and protection against common
                                                                    vulnerabilities. We follow best practices to safeguard your
                                                                    app and user data. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                                                            class="collapsed"><span>07</span> Q: Will I have ownership of
                                                            the app's source code and intellectual property? <i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-7" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Yes, upon project completion and payment, you will have
                                                                    full ownership of the app's source code and intellectual
                                                                    property rights. We believe in transparency and respect for
                                                                    our clients' ownership rights. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                                                            class="collapsed"><span>08</span> Q: Do you provide app updates
                                                            and feature enhancements?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-8" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: WYes, we offer app updates and feature enhancements as
                                                                    part of our post-development services. We help you adapt to
                                                                    evolving user needs and technological advancements, keeping
                                                                    your app up-to-date and competitive. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                                                            class="collapsed"><span>09</span> Q: Can you integrate
                                                            third-party APIs and services into my app?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-9" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Absolutely! We have experience in integrating various
                                                                    third-party APIs and services, such as social media
                                                                    integration, payment gateways, mapping services, and more.
                                                                    We ensure seamless communication between your app and
                                                                    external services. </p>
                                                            </div>
                                                        </li>
                                                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                                                            class="collapsed"><span>10</span> Q: How do you ensure the app's
                                                            user experience (UX) is intuitive and engaging?<i
                                                                class="bx bx-chevron-down icon-show"></i><i
                                                                    class="bx bx-chevron-up icon-close"></i></a>
                                                            <div id="accordion-list-10" class="collapse"
                                                                data-bs-parent=".accordion-list">
                                                                <p> A: Our team includes skilled UX designers who specialize in
                                                                    creating intuitive and engaging user experiences. We conduct
                                                                    user research, develop user personas, and design interfaces
                                                                    that prioritize ease of use, functionality, and visual
                                                                    appeal. </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                                                data-aos-delay="150">&nbsp;</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='contactmains'>
                    <section>
                        <section class="container " id="">
                            <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's
                                have a chat...</h4>
                            </header>
                            <div class="row ">
                                <div class="col-sm-6 contact-text" id="contact-text">
                                    <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
                                    <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br>
                                        <br></br> Regarded as the best IT Company in India, Beangate extends its support to
                                        empowering your businesses with transformative solutions, enabling remarkable
                                        advancements and driving success at every stage of the journey. </p>{/* <p
                                className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email:
                                </b><br></br> beangateITsolutions@gmail.com </p>*/}
                                </div>
                                <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
                                    <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                                        <div class="form-group p-2 "> <label for="name">Name</label> <input type="name"
                                            class="form-control" placeholder="Name" /> </div>
                                        <div class="form-group p-2 "> <label for="email">Email address</label> <input
                                            type="email" class="form-control" placeholder="Email" /> </div>
                                        <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5"
                                            cols="30" type="comment" class="form-control" placeholder="Comment"></textarea>
                                        </div><button type="reset" class="btn silver-button"><i class="fa fa-trash"></i>
                                            Reset</button> <button type="submit" name="submit" class="btn purple-button"
                                                value="send"><i class="fa fa-send-o"></i> Submit</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section>
                        <div className='container-fluid mt-5 mb-5'>
                            <div className='row'>
                                <div className='col-md-12'> </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>



        </div>
    )
}