import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class Box9 extends Component {
render() {
const settings = {
dots: true,
infinite: true,
slidesToShow: 5,
slidesToScroll: 3,
autoplay: true,
autoplayspeed: 500,
infinite: true,

responsive: [
{
breakpoint: 1024,
settings: {
slidesToShow: 4,
slidesToScroll: 4,
infinite: true,
dots: true
}
},
{
breakpoint: 600,
settings: {
slidesToShow: 3,
slidesToScroll: 3,
initialSlide: 3,
dots: true
}
},
{
breakpoint: 480,
settings: {
slidesToShow: 2,
slidesToScroll: 2,
initialSlide: 2,
dots: false
}
},
{
breakpoint: 300,
settings: {
slidesToShow: 1,
slidesToScroll: 1,
initialSlide: 1,
dots: false
}
}
]


};
return (


<div>

    <section className=" px-5 py-4 pb-5 ">
        <div className="container text-center ">
            <div className="row">
                <div className="col-md-12 ">{/* <div className='light2 '>
                        <h2 className="text-white"> Technologies We Use </h2>
                        <div className='lineh3 '> </div>
                    </div>*/}{/* <div className="light4 ">
                        <h2>Technologies We Use </h2>
                        <div className='lineh5 '> </div>
                    </div>*/}<div className="section-title text-white light mt-4">
                        <div className='pb-1'>
                            <h2>Technologies We Use</h2>
                        </div>
                        <div className='lineh5 mb-2'> </div>
                        <p> We Redefine Possibilities By Empowering Brands With Our State-of-the-art Technologies
                            prowess</p>
                    </div>
                    <Slider{...settings}>
                        <div class="item ml-5">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/react-native.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/python-logo.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/phplogo.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/sql.png" className="card-img" />
                                </div>
                            </div>
                        </div>
                        {/* <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/social-media-marketing.png"
                                        className="card-img" /> </div>
                            </div>
                        </div> */}
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/swfit.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/wordpress-2.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/NETLogo.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/mongoDB.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/meanstack.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/machinelearning.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/jquery.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/java.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/java-script.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/html.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/ethical.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/Django.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/data_science.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/css3.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/c-sharp.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/c-plus-plus.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/bootstrap.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/angularl.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class=" imgbg ">
                                <div class="card-body downcard"> <img src="./Technology/android.png"
                                        className="card-img" /> </div>
                            </div>
                        </div>
                        
                        </Slider>
                </div>
            </div>
        </div>
    </section>

</div>



);
}
}