import React from 'react'
import { Helmet } from 'react-helmet';
import "./ourservice.css"

export default function Ourservices() {
  return (
    <div>
      <Helmet>
        <title>Our Services</title>
        <meta name="description" content="Explore Our Comprehensive Range of Software Services at Beangate IT Solutions. From custom software development to robust app solutions, we offer a diverse array of services designed to meet your business's unique needs. Elevate efficiency, streamline processes, and unlock growth opportunities with our expertly crafted software solutions." />
      </Helmet>
      <div className='container-fluid mt-5'>
        <section class=" mt-5 backimgservice " id="">
          <div class="jumbotron float-right-service ">
            <h3 className='tkomar marlesf0'>Ignite Your Business With<br></br> </h3>
            <h3 className='marlesf1'> Our Turbocharged, Technology <br></br> </h3>
            <h3 className='marlesf2'> Services and <spam className="abcdef"> Solutions</spam>
            </h3>
          </div>
        </section>
      </div>
      <section class="container-fluid " id="service-page">
        <header class="row">
          <div class="col-xs-12 text-center mt-4 py-4">
            <h2> Service</h2>
          </div>
        </header>
        <div class="row border p-4 service-page">
          <div class="col-md-5 imggg"> <img src="icons/ourservicesleft.jpg" class="img-responsive" alt="" /> </div>
          <div class="col-md-7 line-height-5 " id="bio">
            <p className='mt-4'>We fuel your business with sustainable success models that outsmart, outlast, and outperform
              the rest. Witness an exhilarating journey of innovation for yourself as we unleash our arsenal of services and
              solutions, meticulously crafted to deliver mind-blowing customer experiences. </p>
            <p className=' mt-4'> From ditching outdated relics to embracing data-driven wizardry, consider us your
              one-stop-shop for all things revolutionary. Together, we'll rewrite the rules of triumph!</p>
          </div>
        </div>
      </section>
      <div class="container-fluidn bgcol pt-5 py-4 ">
        <div class="row px-xl-5 pb-3 pt-4">
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Webdevelopment'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"> <img src='./serviceicon/webdevelopment.png'  alt=''/> </h1>
              <h5 class="font-weight-semi-bold m-0">Web development</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/androiddevelopment'>
            <div class="d-flex align-items-center border shad mb-5 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-2 imgsize "><img src='./serviceicon/androiddevelopment.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0 ">Android development</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Softwaredevelopment'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/softwaredevelopment.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Software development</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Applicationdevelopment'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/applicationdevelopment.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Application development</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Devops'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/devops.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Dev ops</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Digitalmarketing'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/digitalmarketing.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Digital marketing</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Wordpress'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/wordpress.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Wordpress solutions</h5>
            </div>
          </a> </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-1"> <a href='/Webhosting'>
            <div class="d-flex align-items-center border shad mb-4 " style={{ padding: "30px" }}>
              <h1 class=" text-primary m-0 px-3 mr-3 imgsize"><img src='./serviceicon/webhostion.png'  alt='' /> </h1>
              <h5 class="font-weight-semi-bold m-0">Web hosting </h5>
            </div>
          </a> </div>
        </div>
      </div>

    </div>
  )
}
