import "./development.css";
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function Development() {

 
return (
<div>

  <div className=' ' id='devhero'>
    <div className='container-fluid mt-5'>
      <section class=" mt-5 developmentbanner " id="">
        <div class="jumbotrons devjumbo m-0 ">
          <h2 className='mt-5 mb-3'>Web Development </h2>
          <p> Captivating Digital Experiences Designed to Enhance the Bottom Line <br></br>Best Web Development Company
            in India{/* <br></br> improve the way we serve you. */}</p>
          <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
        </div>
      </section>
    </div>
    <section>
      <div className='container-fluid mt-5 p-5 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh3'>
            <h3 className='text-justify'>Web Development Services </h3>
          </div>
          <div className='col-md-7'>
            <p className='text-jusstify'> As no. 1 web development company in India, Beangate extends its gamut of web
              development services to businesses across all verticals including IT, software, real estate, logistics,
              hospitality, and Edtech among many others. </p>
            <p> We build websites that are agile, robust, and smart. Our cybersecurity, eCommerce, and wordpress
              solutions along with website architecture, maintenance, and custom CMS services are second to none. </p>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Accelerate (Not Just Survive) Your Business with Our </h3>
            <h3>Web Design and Web Development Solutions</h3>
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Beangate is helmed by a team of new-age web development spartans who can take
              <br></br> on the army of Xerxes (existing competition) all by themselves. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/web hosting.png'></img> </div>
            <h6 className='text-center mb-4'> Websites</h6>
            <p className='text-center'> Robust, trusted, and agile websites designed to boost your bottom line.</p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/ecommerce.png'></img> </div>
            <h6 className='text-center mb-4'> Ecommerce</h6>
            <p className='text-center'> Smart, sleek, and attractive loaded with cutting-edge solutions and features.
            </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/webapplication.png'></img> </div>
            <h6 className='text-center mb-4'> Web applications</h6>
            <p className='text-center'> Engaging and interactive applications to hook your target audience all the time.
            </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 pt-5 mt-3'> <img src='icons/Webdevelopmentleft.jpg'></img> </div>
          <div className='col-md-6 pt-5 devhjust '>
            <h3 className=' pt-1 '> Take Your Digital Game a Notch Up with State-of-the-art Web Development Company
            </h3>
            <ul className='lignh'>
              <li> <a href=''> Website design </a> </li>
              <li> <a href=''> Website Development </a> </li>
              <li> <a href=''> Ecommerce Website Development </a> </li>
              <li> <a href=''> Python Web Development </a> </li>
              <li> <a href=''> Php Web Development </a> </li>
              <li> <a href='/Wordpress'> Wordpress Development </a> </li>
              <li> <a href=''> Shopify Web Development </a> </li>
              <li> <a href=''> Cybersecurity </a> </li>
              <li> <a href=''> Maintenance </a> </li>
              <li> <a href=''> Backend Solutions </a> </li>
              <li> <a href=''> Frontend Solutions </a> </li>
              <p className='mt-4'> Our role as the no. 1 web design and web development company in India is to ensure
                that we craft custom web solutions to help businesses achieve their business goals. We believe that with
                the right expertise and vision, it is possible to achieve more than just what we aim for. </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev '>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Technologies We Use for Web Design and Web Development </h3>{/* <h3>development solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Choosing the right technologies is all it takes sometimes to make a business
              but they say that it’s tricky.<br></br> No more! Take your business to new heights with our cutting-edge
              technologies </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/frontenddevelopmet.png'></img> </div>
            <h5 className='text-center mb-4'>Frontend Development</h5>
            <p className='text-center'> We build surface layers of your web-based products using technologies such as
              React.js, Vue.js, and Angular. .</p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/backenddevelopment.png'></img> </div>
            <h5 className='text-center mb-4'> Backend Development</h5>
            <p className='text-center'> We leverage the superpowers of Python, Ruby on Rails, and Node.js for
              prototyping and development. </p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/fullstackdevelopment.png'></img> </div>
            <h5 className='text-center mb-4'> Full Stack Development</h5>
            <p className='text-center'> We deliver end-to-end web development solutions using smart frontend and backend
              solutions. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>


    

  

    <section>
      <div className='container mt-5 py-2 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh4'>
            <h4 className=''>Leverage the superpowers of our web design and web development process for the
              success of your business </h4>
          </div>
          <div className='col-md-7 px-5'>
            <p> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web solutions one must
              be detail-savvy. And, in a career spanning these many years and several products, we know exactly how to
              handle projects of various magnitudes while also ensuring seamless process throughout. </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container py-4 '>
        <div className='row'>
          <div className='col-md-1 col-sm-6 text-center'> </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">1</p>
            <h4> Consultation </h4>
            <p class="numbers__caption">Brainstorm ideas to set the direction</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">2</p>
            <h4> Adoption </h4>
            <p class="numbers__caption">Choose a technology for the goals </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light ">3</p>
            <h4> Design </h4>
            <p class="numbers__caption">Craft designs and give shapes to your visions </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">4</p>
            <h4>Develop </h4>
            <p class="numbers__caption">Start coding to bring your product to life</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">5</p>
            <h4> Reach </h4>
            <p class="numbers__caption">Launch the product to the market </p>
          </div>
          <div className='col-md-1 text-center'> </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container py-5 mt-4 '>
        <div className='row'>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">70+</p>
            <p class="numbers__caption">Product Designers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">40+</p>
            <p class="numbers__caption">Frontend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol ">80+</p>
            <p class="numbers__caption">Backend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">45+</p>
            <p class="numbers__caption">Quality Assurance Specialists</p>
          </div>
        </div>
        <div className='row py-4 mt-5'>
          <div className='col-md-5  pb-4 whtimg text-center'> <img src='whybeangatedeve.png'></img> </div>
          <div className='col-md-7 pt-5 devhjust p-4'>
            <h3 className='text-justify pt-3 pb-4'> Why Choose Beangate as Your Web Development Company? </h3>
            <p className='mt-4 lh-lg test '> Whether you're a trailblazing entrepreneur with a vision or a bold leader
              commanding a start-up or a colossal corporation, fear not, for we have your back. At Beangate, we
              transcend the realm of ordinary web development expertise. We are your strategic accomplices, poised to
              navigate the treacherous landscapes of digital creation alongside you. Rest assured, our collaboration
              will result in a product that not only meets but surpasses expectations, leaving both you and your
              customers awestruck. So why settle for anything less than extraordinary? Embrace the enchantment and join
              forces with Beangate to ignite the spark of magic. <br></br> </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 '>
            <h2>Our Web Design and Web Development Company </h2>
          </div>
          <div className='col-md-12 mt-3'>
            <p className='text-center '>Our team is composed of seasoned developers who are carefully selected to add
              value in each stage of your product journey.<br></br> We are the proud team of the following visionaries:
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="container">
   <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loops={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        <SwiperSlide><div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/uidesigners.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">UI Designers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide><div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/uxdesign.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">UX Designers</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/frontend.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Frontend Developers</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/backend.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Backend Developers</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/devopsengineer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">DevOps Engineers</h5>
                <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                  the card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/qaspecialist.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">QA Specialists</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/projectmanager.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Project Managers</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
   </section>





    <section className='bg-dev'>
      <div className='container-fluid pt-4'>
        <div className='row pt-5'>
          <h2 className='mb-3'> Frequently Asked Question </h2>
          <div className='col-md-5 text-center mt-4'> <img src='whatquestion2.png'></img>{/* <div
              className='col-md-5 pt-5 pb-4 mt-3 whtimg'> </div>*/}</div>
          <div className='col-md-7 '>
            <section id="why-us" class="why-us mt-5">
              <div class="container-fluid" data-aos="fade-up">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                    <div class="accordion-list">
                      <ul>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                            class="collapsed"><span>01</span>Q: What sets your web development company apart from
                            others? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We stand out through our exceptional blend of creativity and technical prowess,
                              delivering innovative websites that leave a lasting impression. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                            class="collapsed"><span>02</span>Q: How do you ensure the security of our website during
                            development?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We prioritize security at every step, employing robust measures such as regular code
                              audits and stringent data encryption to safeguard your website from potential threats.
                            </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                            class="collapsed"><span>03</span> Q: Can you handle both front-end and back-end
                            development?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Absolutely! Our skilled team excels in both front-end and back-end development,
                              ensuring seamless integration and a stellar user experience. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                            class="collapsed"><span>04</span> Q: How do you determine the timeline for a web development
                            project? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We meticulously analyze project requirements and devise a realistic timeline, keeping
                              you informed at every stage to ensure timely delivery. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                            class="collapsed"><span>05</span>Q: Will our website be mobile-responsive? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Absolutely! We specialize in creating mobile-responsive websites, providing optimal
                              user experiences across various devices and screen sizes. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                            class="collapsed"><span>06</span> Q: Do you offer ongoing maintenance and support for
                            websites? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we offer comprehensive maintenance and support packages to ensure your website
                              remains secure, up-to-date, and performs at its best. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                            class="collapsed"><span>07</span> Q: Can you integrate third-party services or APIs into our
                            website? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Certainly! We have expertise in integrating various third-party services and APIs,
                              allowing seamless connectivity and expanding the functionality of your website. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                            class="collapsed"><span>08</span> Q: What technologies and frameworks do you work with?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We work with a wide range of cutting-edge technologies and frameworks, including but
                              not limited to HTML5, CSS3, JavaScript, React, Angular, Node.js, and Laravel. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                            class="collapsed"><span>09</span> Q: Can you assist with website hosting and deployment?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Absolutely! We provide assistance with website hosting and deployment, ensuring a
                              smooth transition from development to a live environment. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                            class="collapsed"><span>10</span> Q: How do you ensure effective communication throughout
                            the project?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We prioritize clear and timely communication, assigning dedicated project managers
                              and utilizing collaborative tools to keep you informed and involved every step of the way.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                    data-aos-delay="150">&nbsp;</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div className='contactmains'>
      <section>
        <section class="container " id="">
          <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
              chat...</h4>
          </header>
          <div class="row ">
            <div class="col-sm-6 contact-text" id="contact-text">
              <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
              <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                with transformative solutions, enabling remarkable advancements and driving success at every stage of
                the journey. </p>{/* <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email: </b><br></br>
                beangateITsolutions@gmail.com </p>*/}
            </div>
            <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
              <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                    placeholder="Name" /> </div>
                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                    class="form-control" placeholder="Email" /> </div>
                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                    type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                  class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                  name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className='container-fluid mt-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'> </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>




)
}