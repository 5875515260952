import React from 'react'
import "./box81.css"

export default function Box81() {
return (
<div>

  <div className='container-fluid box81bg mdblockshow'>
    <div className='row'>
      <div className='col-md-12 '>
        <div className="section-title section-tit pt-5 text-white light">
          <div className=''>
            <h2>Our Process</h2>
          </div>
          <div className='lineh33 mb-2'> </div>
          <p className='text-center capitalized '> Explore The Fail-safe Measures That Guarantee Triumph for Your
            Project</p>
        </div>
        <div class=" mycard rot mt-4">
          <p className='pera'><span className='span1'>
              <div class="card bggg text-white"> <img class="card-img" src="icons/research.jpg" alt="Card image"
                  height={"360px"} />
                <div class="card-img-overlay disp0">
                  <p class="card-text ">
                  <h3 className='text-center'>1. Research</h3>
                  <h6 className='text-white hov'>Gather industry insights and user requirements.</h6>
          </p>
        </div>
      </div></span> </p>
      <p className='pera'> <span className='span1'>
          <div class="card bggg text-white"> <img class="card-img" src="icons/ideate.jpg" alt="Card image"
              height={"360px"} />
            <div class="card-img-overlay disp0">
              <p class="card-text ">
              <h3 className='text-center'>2. Ideate</h3>
              <h6 className='text-white hov'>Brainstorm creative solutions for IT challenges.</h6>
      </p>
    </div>
  </div></span> </p>
  <p className='pera'> <span className='span1'>
      <div class="card bggg text-white"> <img class="card-img" src="icons/wireframing.jpg" alt="Card image"
          height={"360px"} />
        <div class="card-img-overlay disp0">
          <p class="card-text ">
          <h3 className='text-center'>3. Wireframming</h3>
          <h6 className='text-white hov'>Create initial visual representations of user interfaces.</h6>
  </p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/prototyping.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>4. Prototyping</h3>
        <h6 className='text-white hov'>Develop functional models for user testing and feedback.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/iteration.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>5.Iteration</h3>
        <h6 className='text-white hov'>Refine designs based on user input and iterative feedback loop.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/ux.jpg" alt="Card image" height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>6. UI/UX Design</h3>
        <h6 className='text-white hov'>Craft intuitive and visually appealing user interfaces.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/documantation.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>7. Documentation</h3>
        <h6 className='text-white hov'>Maintain detailed records of the design and development process.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/development.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>8. Development </h3>
        <h6 className='text-white hov'>Implement designs into functional software or applications.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/testing.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>9. Testing </h3>
        <h6 className='text-white hov'>Conduct thorough quality assurance and user acceptance testing.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/deployment.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>10. Deployment </h3>
        <h6 className='text-white hov'>Roll out the final product or application to users.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/monitoring.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>11. Monitoring </h3>
        <h6 className='text-white hov'>Continuously monitor performance and user feedback for improvements.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/maintenance.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>12. Maintainence </h3>
        <h6 className='text-white hov'>Provide ongoing support, bug fixes, and updates.</h6>
</p>
</div>
</div></span> </p>
<p className='pera'> <span className='span1'>
    <div class="card bggg text-white"> <img class="card-img" src="icons/collaboration.jpg" alt="Card image"
        height={"360px"} />
      <div class="card-img-overlay disp0">
        <p class="card-text ">
        <h3 className='text-center'>13. Collaboration </h3>
        <h6 className='text-white hov'>Foster effective teamwork and communication across departments.</h6>
</p>
</div>
</div></span> </p>
</div>
</div>
</div>
</div>


</div>
)

}