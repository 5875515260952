import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Box10 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 3,
      autoplay: true,
      autoplayspeed: 500,
      infinite: true,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots: false
          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false
          }
        }
      ]


    };
    return (

      <div>

        <section className=" backc px-2 py-4">
          <div className="container text-center mb-4">
            <div className="row pb-3">
              <div className="col-md-12">
                <div className="section-title pt-4 text-white light">
                  <div className='pb-0'><h2>Our Clients</h2>
                  </div>
                  <div className='lineh44 mb-2'>
                  </div>
                </div>
                <Slider{...settings}>

                  <div class="item ml-5 " >
                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-1.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >
                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-2.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >
                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-3.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >

                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-4.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >
                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-5.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >
                    <div class=" imgbg ">

                      <div class="card-body downcard">
                        <img src="./clients/client-6.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                  <div class="item" >
                    <div class=" imgbg ">
                      <div class="card-body downcard">
                        <img src="./clients/client-7.png" className="card-img" />
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

      </div>



    );
  }
}
