


import React from 'react';
import "./Header.css"

const Header = () => {
  return (
    <>
      <header id="header" class="fixed-top ">
        <div class="container d-flex align-items-center">

          <h1 class="logo me-auto"><a href="/"><img src='assets/img/Beangate-lOGOOOO-1.01.png' alt='' style={{ width: "180px" }}></img> </a></h1>

          <nav id="navbar" class="navbar">
            <ul className='dis' id='nodisb'>
              <li><a class="nav-link scrollto active" href="/">Home</a></li>
              {/* <li><a class="nav-link scrollto" href="About">About</a></li> */}
              <li class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown" href="#">About
                </a>
                <ul class="dropdown-menu">
                  <li><a href="About">About Beangate</a></li>
                  <li><a href="Founder">About Founder's</a></li>
                </ul>
              </li>
              <li><a class="nav-link scrollto" href="Service">Services</a></li>
              <li><a class="nav-link   scrollto disabled muted" href="Blogs">Blogs</a></li>
              {/* <li><a class="nav-link scrollto" href="#team">Team</a></li> */}
              <li><a class="nav-link scrollto" target='_self' rel="noreferrer" href="https://academy.beangates.com/">Our Academy</a></li>

              <li class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown" href="#">Our Branches
                </a>
                <ul class="dropdown-menu">
                  {/* <li><a href="About">About Beangate</a></li> */}
                  <li><a href="https://academy.beangates.com/">Jawahar Chouk, Bhopal</a></li>
                </ul>
              </li>
              <li><a class="getstarted scrollto headerbtncolor" href="ContactUs">Contact Us</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>


    </>
  );
}

export default Header;