import React from 'react';

const Expertise = () => {
return (
<section id="services" className="ser services">
  <div className="container">
    <div className="section-title text-white light">
      <div className='pb-2 '>
        <h2>Expertise</h2>
      </div>
      <div className='lineh3  mb-2'> </div>
      <p className=''>Best IT Company in India Accelerating Business Success Through Customized IT Solutions</p>
    </div>
    <div className="row ">
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/web.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Web Development</h5>
            <p className=' text-white text-center'>
            <p className=' text-white text-center'>Crafting web experiences that empower and inspire.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/soft.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white text-center'>Softwear Development</h5>
            <p class="card-text text-center">
            <p className=' text-white'>Building bespoke software solutions for endless success.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/Dev.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Dev Opps</h5>
            <p class="card-text text-white text-center ">
            <p>Streamline your development and operations for unparalleled efficiency.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/Wordpress.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Wordpress</h5>
            <p class="card-text text-white text-center">
            <p>Empowering online success with premium WordPress solutions.</p>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/android.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Andriod Development</h5>
            <p class="card-text text-white text-center">
            <p>Transforming ideas into stellar Android solutions.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/Application.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Application Development</h5>
            <p class="card-texttext-white text-center ">
            <p>Building next-generation apps that redefine possibilities.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/Digital.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Digital Marketing</h5>
            <p class="card-text text-white text-center">
            <p>Elevate your online presence with results-driven digital marketing solutions.</p>
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
        <div class="card bggg text-white"> <img class="card-img " src="icons/Web host.jpg" alt="Card image"
            height={"225px"} />
          <div class="card-img-overlay disp1">
            <h5 className='text-center text-white'>Web Hosting</h5>
            <p class="card-text text-white text-center">
            <p>Power your online presence with unmatched speed, reliability, and support.</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
);
}

export default Expertise;