import React from 'react';
import "../App.css"



const Box8 = () => {
    return (
        <div className=' process bg-dev mdblocknone'>
            <div className='container-fluid '>
                <div className='row just'>
                        <div class="col-md-6 card bg-white mb-4" >
                            <div class="card-body forh1">
                                <h1 class="card-title text-center fontt text-black">Our Process </h1> <br/>
                                <p class="card-text text-black pl-4 text-center">Explore The Fail-safe Measures That Guarantee Triumph for Your Project</p>
                                
                            </div>
                        </div>
                        <div class="col-md-2 card  bg-primary mb-4 ml-3 " >
                            <div class="card-body forh1 ">
                                {/* <h1 className='ani text-center '><i class="bi bi-search-heart-fill  "></i></h1> */}
                                <h1 className='text-center'>
                                <i className='text-center icc2'><img src="icons/research.png"  alt="Research"/></i>
                                </h1>
                                <h1 class="card-title  text-center">1.Research </h1> <br/>
                                
                                <p class="card-text">Gather industry insights and user requirements.</p>
                                
                            </div>
                        </div>
                        
                        <div class="col-md-3 card bg-danger mb-4" >
                            <div class="card-body forh1">
                                <div className='card-head'>
                                {/* <h1 className='text-center ani'><i class="bi bi-lightbulb"></i></h1> */}
                                {/* <img src="../../public/idea.png" alt=""/> */}
                                <h1 className='text-center'>
                                <i className='text-center icc2'><img src="icons/ideate.png"  alt="IDEAT"/></i>
                                </h1>
                                </div>
                                <h1 class="card-title  text-center">2. IDEAT </h1>
                                <br/>
                                <p class="card-text">Brainstorm creative solutions for IT challenges.</p>
                                
                            </div>
                        </div>
                
                </div>


                <div className='row just reve'>
                        <div class="col-md-3 bg-success card mb-4 border-light" >
                            <div class="card-body forh1">
                                <h1 className='text-center '><i className='text-center icc2'><img src="icons/ui.png"  alt="UX/UI Design"/></i></h1> 
                                <h1 class="card-title text-center">6. UX/UI Design </h1> <br/>
                                <p class="card-text"> Craft intuitive and visually appealing user interfaces.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-2 card bg-dark mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center'><i className='text-c enter  icc2'><img src="icons/iteration.png"  alt="Iteration"/></i></h1>
                                <h1 class="card-title text-center">5. Iteration </h1> <br/>
                                <p class="card-text">Refine designs based on user input and iterative feedback loop.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-2 card bg-info mb-4" >
                            <div class="card-body forh1">
                            <h1 className='text-center'><i className='text-center icc2'><img src="icons/prototyping.png"  alt="Prototyping"/></i></h1>
                                <h1 class="card-title text-center">4.Prototyping </h1> <br/>
                                <p class="card-text">Develop functional models for user testing and feedback.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-4 card bg-warning mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center text-black'><i className='text-center icc2'><img src="icons/wireframing.png"  alt="Wireframming"/></i></h1>
                                <h1 class="card-title text-center text-black">3. Wireframming </h1> <br/>
                                <p class="card-text text-black">Create initial visual representations of user interfaces.</p>
                                
                            </div>
                        </div>
                
                </div>


                <div className='row just'>
                        <div class="col-md-3 card bg-danger mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center'><i className='text-center icc2'><img src="icons/documentation.png"  alt="Documentation"/></i></h1>
                                <h1 className='text-center'></h1>
                                <h1 class="card-title text-center">7. Documentation </h1> <br/>
                                <p class="card-text">Maintain detailed records of the design and development process.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-5 card bg-warning mb-4" >
                            <div class="card-body forh1 ">
                                <h1 className='text-center text-black'><i className='text-center icc2'><img src="icons/development.png"  alt="Development"/></i></h1>
                                <h1 class="card-title text-center  text-black ">8. Development  </h1> <br/>
                                <p class="card-text text-black">Implement designs into functional software or applications.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-3 card bg-dark mb-4" >
                            <div class="card-body forh1 ">
                                <h1 className='text-center '><i className='text-center bg-white icc2'><img src="icons/testing.png"  alt="Testing"/></i></h1>
                                <h1 class="card-title text-center">9.Testing </h1>  <br/>
                                <p class="card-text">Conduct thorough quality assurance and user acceptance testing.</p>
                                
                            </div>
                        </div>
                        
                
                </div>


                <div className='row just reve'>
                        <div class="col-md-4 card bg-info mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center text-black'><i className='text-center icc2'><img src="icons/collaboration.png"  alt="Collaboration"/></i></h1>
                                <h1 class="card-title text-center text-black">13. Collaboration </h1> <br/>
                                <p class="card-text text-black">Foster effective teamwork and communication across departments.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-3 card bg-dark mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center'><i className='text-center icc2'><img src="icons/maintainance.png"  alt="Maintenance"/></i></h1>
                                <h1 class="card-title text-center">12. Maintenance </h1> <br/>
                                <p class="card-text">Provide ongoing support, bug fixes, and updates.</p>
                                
                            </div>
                        </div>
                        
                        <div class="col-md-2 card bg-danger mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center'><i className='text-center icc2'><img src="icons/monitoring.png"  alt="Monitoring "/></i></h1>
                                <h1 class="card-title">11.Monitoring  </h1> <br/>
                                <p class="card-text">Continuously monitor performance and user feedback for improvements.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-2 card bg-success mb-4" >
                            <div class="card-body forh1">
                                <h1 className='text-center'><i className='text-center icc2'><img src="icons/deployment.png"  alt="Deployment"/></i></h1>
                                <h1 class="card-title">10. Deployment </h1> <br/>
                                <p class="card-text">Roll out the final product or application to users.</p>
                                
                            </div>
                        </div>
                
                </div>






            </div>


            



        </div>
    );
}

export default Box8;