import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import "./wordpress.css"

export default function Wordpress() {
return (
<div>

  <div className=' ' id='devhero'>
    <div className='container-fluid mt-5'>
      <section class=" mt-5 wordpressbanner " id="">
        <div class="jumbotrons wordpressjumbo m-0 ">
          <h2 className='mt-5 mb-3'>Wordpress Development Company </h2>
          <p> Crafting Your Digital Identity with No. 1 Wordpress Solutions <br></br>Unrivaled Wordpress Development
            Company in India{/* <br></br> improve the way we serve you. */}</p>
          <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
        </div>
      </section>
    </div>
    <section>
      <div className='container-fluid mt-4 p-5 devp'>
        <div className='row mt-4 py-4'>
          <div className='col-md-5 devh3'>
            <h3 className='text-justify'>Wordpress Development Services </h3>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Beangate specializes in powerful WordPress websites. Our expert team crafts captivating
              online experiences tailored to your needs. Maximize your online presence with Beangate's top-notch
              development services. </p>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Create Wordpress Solutions with Unmatched Expertise </h3>
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> From custom website development to plugin integration and responsive design,
              our expert team ensures a seamless user experience. Harness the power of WordPress with Beangate and
              unlock limitless possibilities for your online success. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Custom WordPress Website Development.png'></img> </div>
            <h6 className='text-center mb-4'> Custom WordPress Website Development</h6>
            <p className='test'> Beangate specializes in creating tailored WordPress websites that align with your brand
              identity and business objectives. Our team of skilled developers transforms your vision into a
              pixel-perfect reality.</p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/WordPress Plugin Integration.png'></img> </div>
            <h6 className='text-center mb-4'>WordPress Plugin Integration</h6>
            <p className='test'> Enhance the functionality of your WordPress website with our plugin integration
              services. Beangate seamlessly integrates essential plugins to expand the capabilities of your website,
              whether it's adding e-commerce functionality, improving SEO, or optimizing performance. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Responsive WordPress Design.png'></img> </div>
            <h6 className='text-center mb-4'> Responsive WordPress Design</h6>
            <p className='test'> In today's mobile-first world, having a responsive website is essential. Beangate's
              WordPress solutions include responsive design services, ensuring your website looks and functions
              flawlessly across all devices and screen sizes. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 pt-5 mt-3'> <img src='icons/wordpressleft.jpg'></img> </div>
          <div className='col-md-6 pt-5 devhjust '>
            <h3 className='text-justify pt-1 '> Superlative Wordpress Solutions </h3>
            <p> Beangate offers a wide range of wordpress solutions including: </p>
            <ul className='lignh'>
              <li> <a href=''> Custom WordPress Website Development </a> </li>
              <li> <a href=''> WordPress Theme Customization </a> </li>
              <li> <a href=''> Plugin Development and Integration </a> </li>
              <li> <a href=''> WordPress Migration and Upgrades </a> </li>
              <li> <a href=''> WordPress Website Maintenance and Support </a> </li>
              <li> <a href=''> Responsive WordPress Design </a> </li>
              <li> <a href=''> WooCommerce Development (e-commerce) </a> </li>
              <li> <a href=''> WordPress SEO Optimization </a> </li>
              <li> <a href=''> WordPress Security Enhancements </a> </li>
              <li> <a href=''> WordPress Multisite Setup and Management </a> </li>
              <li> <a href=''> </a> </li>
              <li> <a href=''> Custom WordPress Plugin Development </a> </li>
              <li> <a href=''> PSD to WordPress Conversion </a> </li>
              <li> <a href=''> WordPress Speed Optimization </a> </li>
              <li> <a href=''> WordPress Blog Development </a> </li>
              <li> <a href=''> WordPress Training and Consultation </a> </li>
              <p className='mt-4'> As India's leading wordpress development company, Beangate earns reputation for its
                gamut of bespoke wordpress services. </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev '>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Technologies We Use for Web Design and Web Development </h3>{/* <h3>development solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Choosing the right technologies is all it takes sometimes to make a business
              but they say that it’s tricky.<br></br> No more! Take your business to new heights with our cutting-edge
              technologies </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/web hosting.png'></img> </div>
            <h5 className='text-center mb-4'>Frontend Development</h5>
            <p> We build surface layers of your web-based products using technologies such as React.js, Vue.js, and
              Angular. .</p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/android.png'></img> </div>
            <h5 className='text-center mb-4'> Backend Development</h5>
            <p> We leverage the superpowers of Python, Ruby on Rails, and Node.js for prototyping and development. </p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/cloud.png'></img> </div>
            <h5 className='text-center mb-4'> Full Stack Development</h5>
            <p> We deliver end-to-end web development solutions using smart frontend and backend solutions. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 py-2 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh4'>
            <h4 className='text-justify'>Leverage the superpowers of our web design and web development process for the
              success of your business </h4>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web
              solutions one must be detail-savvy. And, in a career spanning these many years and several products, we
              know exactly how to handle projects of various magnitudes while also ensuring seamless process throughout.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container py-4 '>
        <div className='row'>
          <div className='col-md-1 col-sm-6 text-center'> </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">1</p>
            <h4> Consultation </h4>
            <p class="numbers__caption">Brainstorm ideas to set the direction</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">2</p>
            <h4> Adoption </h4>
            <p class="numbers__caption">Choose a technology for the goals </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light ">3</p>
            <h4> Design </h4>
            <p class="numbers__caption">Craft designs and give shapes to your visions </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">4</p>
            <h4>Develop </h4>
            <p class="numbers__caption">Start coding to bring your product to life</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">5</p>
            <h4> Reach </h4>
            <p class="numbers__caption">Launch the product to the market </p>
          </div>
          <div className='col-md-1 text-center'> </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container py-5 mt-4 '>
        <div className='row'>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">70+</p>
            <p class="numbers__caption">Product Designers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">40+</p>
            <p class="numbers__caption">Frontend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol ">80+</p>
            <p class="numbers__caption">Backend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">45+</p>
            <p class="numbers__caption">Quality Assurance Specialists</p>
          </div>
        </div>
        <div className='row py-4 mt-5'>
          <div className='col-md-5 pb-4 whtimg'> <img src='whybeangatedeve.png'></img> </div>
          <div className='col-md-7 pt-5 devhjust p-4'>
            <h3 className='text-justify pt-4 pb-4'> Why Choose Beangate as Your Wordpress Development Company? </h3>
            <p className='mt-4 lh-lg test'> Choose Beangate as your WordPress development company for unparalleled
              expertise, tailored solutions, and stunning design. Our experienced team crafts custom websites that
              perfectly reflect your brand identity and deliver an exceptional user experience. With Beangate, you can
              expect top-notch WordPress development services that elevate your online presence and drive success. </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 '>
            <h2>Our Wordpress Development Company </h2>
          </div>
          <div className='col-md-12 mt-3'>
            <p className='text-center '>Regarded as the best wordpress development company in India, Beangate is helmed
              by a team of highly skilled professionals who are masters of their craft. With a wealth of experience and
              expertise, our wordpress experts are adept at implementing performance. </p>
          </div>
        </div>
      </div>
    </section>



    <section className="container">
   <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loops={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Lead WordPress Developer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Lead WordPress Developer </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Senior WordPress Designer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Senior WordPress Designer</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/WordPress Theme Specialist.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">WordPress Theme Specialist</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/WordPress Plugin Developer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">WordPress Plugin Developer</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/WordPress SEO Strategist.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">WordPress SEO Strategist</h5>
                <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                  the card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/WooCommerce Specialist.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">WooCommerce Specialist</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Front-end Developer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Front-end Developer</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
   </section>


    
    
    <section className='bg-dev'>
      <div className='container-fluid pt-4'>
        <div className='row pt-5'>
          <h2 className='mb-3'> Frequently Asked Question </h2>
          <div className='col-md-5 text-center mt-4'> <img src='whatquestion2.png'></img>{/* <div
              className='col-md-5 pt-5 pb-4 mt-3 whtimg'> </div>*/}</div>
          <div className='col-md-7 '>
            <section id="why-us" class="why-us mt-5">
              <div class="container-fluid" data-aos="fade-up">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                    <div class="accordion-list">
                      <ul>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                            class="collapsed"><span>01</span>Q: What is WordPress development? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: WordPress development involves creating and customizing websites using the WordPress
                              content management system. It includes tasks such as theme development, plugin
                              integration, customization, and optimizing website functionality. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                            class="collapsed"><span>02</span>Q: Why should I choose WordPress for my website?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: WordPress offers a user-friendly interface, a vast library of themes and plugins, and
                              excellent SEO capabilities. It allows for easy content management, scalability, and a
                              vibrant community for support and updates. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                            class="collapsed"><span>03</span> Q: Can you customize the design of my WordPress website?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, Beangate provides custom WordPress design services. We can create a unique and
                              visually appealing website that aligns with your brand identity and meets your specific
                              design requirements. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                            class="collapsed"><span>04</span> Q: Can you migrate my existing website to WordPress? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                            <p> AA: Absolutely! Beangate offers WordPress migration services. We can seamlessly transfer
                              your existing website to WordPress, ensuring all content, functionality, and design
                              elements are preserved. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                            class="collapsed"><span>05</span>Q: Do you provide ongoing support and maintenance for
                            WordPress websites? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we offer WordPress website maintenance and support services. We can handle
                              regular updates, backups, security enhancements, and troubleshooting to ensure your
                              website runs smoothly. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                            class="collapsed"><span>06</span> Q: Can you optimize my WordPress website for search
                            engines? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, Beangate specializes in WordPress SEO optimization. We can implement best
                              practices, optimize meta tags, improve site speed, and enhance overall SEO performance to
                              boost your website's visibility. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                            class="collapsed"><span>07</span> Q: Can you integrate e-commerce functionality into my
                            WordPress website? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Certainly! Beangate has expertise in WooCommerce development. We can seamlessly
                              integrate a robust e-commerce platform into your WordPress website, allowing you to sell
                              products or services online. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                            class="collapsed"><span>08</span> Q: Do you provide training on managing and updating
                            WordPress websites?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we offer WordPress training and consultation services. We can provide you with
                              the necessary knowledge and guidance to manage and update your WordPress website
                              efficiently. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                            class="collapsed"><span>09</span> Q: How long does it take to develop a custom WordPress
                            website?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: The timeline for developing a custom WordPress website depends on various factors,
                              such as project complexity and requirements. Beangate will provide you with an estimated
                              timeline after assessing your specific needs. </p>
                          </div>
                        </li>{/* <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                            class="collapsed"><span>10</span> Q: How do you ensure effective communication throughout
                            the project?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We prioritize clear and timely communication, assigning dedicated project managers
                              and utilizing collaborative tools to keep you informed and involved every step of the way.
                            </p>
                          </div>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                    data-aos-delay="150">&nbsp;</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div className='contactmains'>
      <section>
        <section class="container " id="">
          <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
              chat...</h4>
          </header>
          <div class="row ">
            <div class="col-sm-6 contact-text" id="contact-text">
              <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
              <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                with transformative solutions, enabling remarkable advancements and driving success at every stage of
                the journey. </p>{/* <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email: </b><br></br>
                beangateITsolutions@gmail.com </p>*/}
            </div>
            <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
              <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                    placeholder="Name" /> </div>
                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                    class="form-control" placeholder="Email" /> </div>
                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                    type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                  class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                  name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className='container-fluid mt-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'> </div>
          </div>
        </div>
      </section>
    </div>
  </div>



</div>
)
}