import React from 'react'

export default function Box7() {
return (
<div>
    <section class="box-7">
        <div class="container">
            <div className="section-title mt-5">
                <div className='pb-1 '>
                    <h2 className=''>Why Beangate</h2>
                </div>
                <div className='lineh4'> </div>
                <p className='mt-2 text-center'>Discover Why We Are the Pinnacle Choice for Businesses in India</p>{/*
                <p className='text-center'>Unlock the Power of IT Brilliance</p>*/}
            </div>
            <div class="row mt5 mt-5">
                <div className='col-md-5 text-justify '> <img src="whybeangate.jpg" alt="" /> </div>
                <div className='col-md-7 section-title text-justify'>
                    <p className=' mt-1'> In the vast landscape of technological advancements, where innovation becomes
                        the driving force behind success, Beangates, your most trusted IT company in India, stands tall
                        as a beacon of creativity and excellence. We are nestled in the heart of India's digital
                        revolution—igniting the sparks of ingenuity to transform your business into a dynamic
                        powerhouse. </p><br />
                    <p className=''> At the heart of our company lies a team of visionary minds, fueled by a relentless
                        passion for all things IT. Our collective expertise transcends boundaries, encompassing a
                        multitude of domains such as software development, cloud computing, cybersecurity, and more.
                        From the moment you step into our digital realm, you will witness firsthand the fusion of
                        imagination and technical prowess that sets us apart. </p>
                </div>
            </div>
        </div>{/* <div class="container">
            <div class="row mt-5">
                <div className='col-md-7 text-justify section-title'>
                    <p className=''> At the heart of our company lies a team of visionary minds, fueled by a relentless
                        passion for all things IT. Our collective expertise transcends boundaries, encompassing a
                        multitude of domains such as software development, cloud computing, cybersecurity, and more.
                        From the moment you step into our digital realm, you will witness firsthand the fusion of
                        imagination and technical prowess that sets us apart. </p><br /> <br />
                    <p className=''> &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; At the heart of our company lies a team of
                        visionary minds, fueled by a relentless passion for all things IT. Our collective expertise
                        transcends boundaries, encompassing a multitude of domains such as software development, cloud
                        computing, cybersecurity, and more. From the moment you step into our digital realm, you will
                        witness firsthand the fusion of imagination and technical prowess that sets us apart. </p>
                </div>
                <div className='col-md-5 text-justify text-white'> <img src="box7-1.jpg" alt="" /> </div>
            </div>
        </div>*/}
    </section>
</div>
)
}