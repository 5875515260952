import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';


import "./devops.css"

export default function Devops() {
return (
<div>

  <div className=' ' id='devhero'>
    <div className='container-fluid mt-5'>
      <section class=" mt-5 devopsbanner " id="">
        <div class=" devopsjumbo m-0 ">
          <h2 className='mt-5 mb-3'>Dev Ops </h2>
          <p> Accelerate Your Product Deployment with Devop Services <br></br>Top Devop Company in India{/* <br></br>
            improve the way we serve you. */}</p>
          <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
        </div>
      </section>
    </div>
    <section>
      <div className='container-fluid mt-5 p-5 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh3'>
            <h3 className='text-justify'>DevOps Services </h3>
          </div>
          <div className='col-md-7 '>
            <p>Welcome to Beangagte, your trusted provider of Devop solutions. We specialize in helping businesses
              optimize their software development and delivery processes through efficient automation, seamless
              collaboration, and continuous integration and deployment practices. Our experienced team of Devop
              engineers works closely with clients to streamline workflows, reduce time-to-market, and enhance the
              overall efficiency of their software development lifecycle. </p>{/* <p> We build websites that are agile,
              robust, and smart. Our cybersecurity, eCommerce, and wordpress solutions along with website architecture,
              maintenance, and custom CMS services are second to none. </p>*/}
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Optimize the Software Deployment Process with No. 1 Devop Solutions </h3>{/* <h3>Web Design and Web
              Development Solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Beangate is helmed by a Unleash the full power of your software development and
              delivery processes with Beangate's DevOps services that are hotter than a supernova. Our crack team of
              DevOps experts is on a mission to turbocharge your development workflows. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Continuous Integration and Deployment.png'></img> </div>
            <h6 className='text-center mb-4'> Continuous Integration and Deployment </h6>
            <p className='test'> Scale your business with ease using our infrastructure automation services. Our DevOps
              team leverages cutting-edge tools to automate provisioning, configuration, and managemen.</p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Infrastructure Automation.png'></img> </div>
            <h6 className='text-center mb-4'> Infrastructure Automation </h6>
            <p className='test'> Gain real-time insights into your systems' health and performance with our monitoring
              and optimization solutions. We help identify bottlenecks, optimize resource utilization, and enhance
              overall system responsiveness. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Monitoring and Performance Optimization.png'></img> </div>
            <h6 className='text-center mb-4'> Monitoring and Performance Optimization </h6>
            <p className='test'> Engaging and interactive applications to hook your target audience all the time. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 pt-5 mt-3'> <img src='icons/devopsleft.jpg'></img> </div>
          <div className='col-md-6 pt-5 devhjust '>
            <h3 className='text-justify pt-1 '> Unmatched Devop Solutions by Beangate IT Solutions </h3>
            <p> Beangate offers a wide range of solutions including:</p>
            <ul className='lignh'>
              <li> <a href=''> Continuous Integration and Deployment (CI/CD) </a> </li>
              <li> <a href=''> Infrastructure as Code (IaC) </a> </li>
              <li> <a href=''> Containerization and Orchestration </a> </li>
              <li> <a href=''> Configuration Management </a> </li>
              <li> <a href=''> Monitoring and Observability </a> </li>
              <li> <a href='/Wordpress'> Infrastructure Automation </a> </li>
              <li> <a href=''> Cloud Adoption and Migration </a> </li>
              <li> <a href=''> DevOps Culture and Collaboration </a> </li>
              <li> <a href=''> Security and Compliance </a> </li>
              <li> <a href=''> Performance Optimization and Scaling: </a> </li>{/* <li> <a href=''> Frontend Solutions
                </a> </li>*/}<p className='mt-4'> As India's leading devop solutions company, Beangate earns reputation
                for its gamut of bespoke devop services. </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev '>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Technologies We Use for Web Design and Web Development </h3>{/* <h3>development solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Choosing the right technologies is all it takes sometimes to make a business
              but they say that it’s tricky.<br></br> No more! Take your business to new heights with our cutting-edge
              technologies </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/web hosting.png'></img> </div>
            <h5 className='text-center mb-4'>Frontend Development</h5>
            <p className='test'> We build surface layers of your web-based products using technologies such as React.js,
              Vue.js, and Angular. .</p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/android.png'></img> </div>
            <h5 className='text-center mb-4'> Backend Development</h5>
            <p className='test'> We leverage the superpowers of Python, Ruby on Rails, and Node.js for prototyping and
              development. </p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/cloud.png'></img> </div>
            <h5 className='text-center mb-4'> Full Stack Development</h5>
            <p className='test'> We deliver end-to-end web development solutions using smart frontend and backend
              solutions. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 py-2 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh4'>
            <h4 className='text-justify'>Leverage the superpowers of our web design and web development process for the
              success of your business </h4>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web
              solutions one must be detail-savvy. And, in a career spanning these many years and several products, we
              know exactly how to handle projects of various magnitudes while also ensuring seamless process throughout.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container py-4 '>
        <div className='row'>
          <div className='col-md-1 col-sm-6 text-center'> </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">1</p>
            <h4> Consultation </h4>
            <p class="numbers__caption">Brainstorm ideas to set the direction</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">2</p>
            <h4> Adoption </h4>
            <p class="numbers__caption">Choose a technology for the goals </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light ">3</p>
            <h4> Design </h4>
            <p class="numbers__caption">Craft designs and give shapes to your visions </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">4</p>
            <h4>Develop </h4>
            <p class="numbers__caption">Start coding to bring your product to life</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">5</p>
            <h4> Reach </h4>
            <p class="numbers__caption">Launch the product to the market </p>
          </div>
          <div className='col-md-1 text-center'> </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container py-5 mt-4 '>
        <div className='row'>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">70+</p>
            <p class="numbers__caption">Product Designers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">40+</p>
            <p class="numbers__caption">Frontend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol ">80+</p>
            <p class="numbers__caption">Backend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">45+</p>
            <p class="numbers__caption">Quality Assurance Specialists</p>
          </div>
        </div>
        <div className='row py-4 mt-5'>
          <div className='col-md-5 pb-2 whtimg'> <img src='whybeangatedeve.png'></img> </div>
          <div className='col-md-7 pt-5 devhjust p-4'>
            <h3 className='text-justify pt-3 pb-4'> Why Choose Beangate as Your Mobile App Development Company? </h3>
            <p className='mt-4 lh-lg test'> Choose Beangate as your Devop solutions development company for unmatched
              expertise, cutting-edge technology, and a passion for innovation. Our experienced team excels in
              streamlining software delivery processes, automating infrastructure, and enhancing collaboration. With our
              tailored solutions, you'll benefit from accelerated deployments, improved scalability, and optimized
              performance. Trust Beangate to unleash the power of DevOps, driving your business towards success in the
              dynamic digital landscape. </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 '>
            <h2>Our Devop Solutions Company </h2>
          </div>
          <div className='col-md-12 mt-3'>
            <p className='text-center '>Beangate boasts a formidable DevOps team comprising highly skilled professionals
              who are masters of their craft. With a wealth of experience and expertise, our DevOps experts are adept at
              implementing seamless CI/CD pipelines, automating infrastructure, and optimizing performance.
              Collaborative and driven by innovation, our DevOps team is committed to delivering exceptional solutions
              that propel businesses towards continuous improvement and success in the ever-evolving tech industry.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="container">
   <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loops={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/devopsengineer.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">DevOps Engineers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Systems Administrators.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Systems Administrators </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Release Managers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Release Managers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Cloud Architects.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Cloud Architects </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Automation Specialists.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Automation Specialists </h5>
                <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                  the card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Quality Assurance (QA) Engineers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Quality Assurance (QA) Engineers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Security Specialists.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Security Specialists </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Data Analysts.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Data Analysts </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
   </section>



    
    <section className='bg-dev'>
      <div className='container-fluid pt-4'>
        <div className='row pt-5'>
          <h2 className='mb-3'> Frequently Asked Question </h2>
          <div className='col-md-5 text-center mt-4'> <img src='whatquestion2.png'></img> </div>
          <div className='col-md-7 '>
            <section id="why-us" class="why-us mt-5">
              <div class="container-fluid" data-aos="fade-up">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                    <div class="accordion-list">
                      <ul>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                            class="collapsed"><span>01</span>Q: What is DevOps? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: DevOps is a collaborative approach that combines software development (Dev) and IT
                              operations (Ops) to streamline the software delivery process. It emphasizes automation,
                              continuous integration, and continuous delivery, enabling faster and more reliable
                              software releases. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                            class="collapsed"><span>02</span>Q: What are the benefits of implementing DevOps?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Implementing DevOps brings numerous benefits, including faster time-to-market,
                              improved software quality, increased collaboration between teams, enhanced scalability,
                              better resource utilization, and improved customer satisfaction. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                            class="collapsed"><span>03</span> Q: How can DevOps improve the software development
                            process?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: DevOps improves the software development process by fostering closer collaboration
                              between developers and operations teams, automating repetitive tasks, enabling continuous
                              integration and deployment, ensuring faster feedback loops, and promoting a culture of
                              transparency and shared responsibility. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                            class="collapsed"><span>04</span> Q: What tools are commonly used in DevOps? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Commonly used DevOps tools include configuration management tools like Ansible and
                              Chef, version control systems like Git, continuous integration and continuous delivery
                              (CI/CD) tools like Jenkins and Travis CI, containerization tools like Docker and
                              Kubernetes, monitoring tools like Prometheus and Grafana, and collaboration tools like
                              Slack and Jira. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                            class="collapsed"><span>05</span>Q: Can DevOps be implemented in legacy environments? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, DevOps can be implemented in legacy environments. While it may require some
                              modifications and adjustments, the principles of DevOps, such as automation,
                              collaboration, and continuous improvement, can be applied to improve the efficiency and
                              reliability of legacy systems. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                            class="collapsed"><span>06</span> Q: How does DevOps enhance security? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: DevOps enhances security by integrating security practices into the software
                              development lifecycle, implementing security controls and monitoring throughout the
                              process, conducting regular vulnerability assessments and penetration testing, and
                              promoting a culture of security awareness and responsibility among team members. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                            class="collapsed"><span>07</span> Q: What is the role of automation in DevOps?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Automation plays a crucial role in DevOps by reducing manual errors, increasing
                              efficiency, enabling faster and more frequent software releases, facilitating consistent
                              and reproducible environments, and freeing up resources to focus on higher-value tasks.
                            </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                            class="collapsed"><span>08</span> Q: How does DevOps support cloud infrastructure?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: WDevOps and cloud infrastructure go hand in hand. DevOps practices enable the
                              seamless deployment and management of applications on cloud platforms, leveraging the
                              scalability and flexibility offered by cloud services. It also facilitates the automation
                              of cloud infrastructure provisioning and configuration. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                            class="collapsed"><span>09</span> Q: Can DevOps be applied to non-technical industries?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, DevOps principles can be applied to non-technical industries as well. While the
                              implementation may differ, the core concepts of collaboration, automation, and continuous
                              improvement can be adopted to improve processes, enhance productivity, and drive
                              innovation across various industries. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                            class="collapsed"><span>10</span> Q: How can a DevOps solution provider help my
                            organization?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: A DevOps solution provider can assess your organization's current processes, identify
                              areas for improvement, and design a customized DevOps strategy tailored to your specific
                              needs. They can help implement the necessary tools and technologies, provide guidance and
                              training to your teams, and support the ongoing maintenance and optimization of your
                              DevOps practices. </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                    data-aos-delay="150">&nbsp;</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div className='contactmains'>
      <section>
        <section class="container " id="">
          <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
              chat...</h4>
          </header>
          <div class="row ">
            <div class="col-sm-6 contact-text" id="contact-text">
              <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
              <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                with transformative solutions, enabling remarkable advancements and driving success at every stage of
                the journey. </p>{/* <p className='px-4'><b>Fill the form or reach out to us at</b>
                <br></br>+91-7471112020<br></br>+91-9752740090<br></br>+0755-3100296<br></br><b>Email:
                </b><br></br>beangateITsolutions@gmail.com</p>*/}
            </div>
            <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
              <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                    placeholder="Name" /> </div>
                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                    class="form-control" placeholder="Email" /> </div>
                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                    type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                  class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                  name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className='container-fluid mt-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'> </div>
          </div>
        </div>
      </section>
    </div>
  </div>



</div>
)
}