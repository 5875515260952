import React from 'react';
import "./Blogs.css";
const Blogs = () => {
  return (
    <>
      <section class="container-fluid mt-5 py-5 col-" id="splash-pageblog">
        <div class="jumbotron rightblog mt-5 pt-5">
          <h2 className='pt-5 mt-5 '> Revolutionizing IT Company</h2><br></br> <h2 className=''> Innovative  Strategies and Insights</h2>
        </div>
      </section>


      <section className=''>

        <div className='row g-4 py-5'>
          <div className='col-md-12'>
            <div class="container-fluid">
              <div class="row g-4 py-5">
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' width={"6px"} />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="product-single-card">
                    <div class="product-top-area">
                      <div class="product-discount"> 10% </div>
                      <div class="product-img">
                        <div class="first-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                        <div class="hover-view"> <img src="./assets/img/featured-2.jpg" alt="logo" class="img-fluid"
                          onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" /> </div>
                      </div>
                      <div class="sideicons"> <button class="sideicons-btn"> <i class="fa-solid fa-cart-plus"></i> </button>
                        <button class="sideicons-btn"> <i class="fa-solid fa-eye"></i> </button> <button
                          class="sideicons-btn"> <i class="fa-solid fa-heart"></i> </button> <button class="sideicons-btn"> <i
                            class="fa-solid fa-shuffle"></i> </button> </div>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div>
                        <div className='d-flex p-2 blogimg'> <img src='./user.jpg' />
                          <div className='ml-3'>
                            <h5>Sanjay Dhurve</h5> <span>02/03/2023</span>
                          </div>
                        </div>
                      </div>
                      <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions, Beangates
                        prides itself on being a trusted partner for resolving unique challenges of businesses and offering
                        them tailored end-to-end solutions that drive growth and enhance overall productivity. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </>
  );
}

export default Blogs;
