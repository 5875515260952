import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import "./webhosting.css"

export default function Webhosting() {
  
  return (
    <div>

      <div className=' ' id='devhero'>
        <div className='container-fluid mt-5'>
          <section class=" mt-5 webhostingbanner " id="">
            <div class="jumbotrons webhostingjumbo m-0 ">
              <h2 className='mt-5 mb-3 text-white'>Web Hosting Services </h2>
              <p className='text-white'> Fast, Secure, and Reliable Web Hosting Services <br></br>No. 1 Web Hosting Services
                in India{/* <br></br> improve the way we serve you. */}</p>
              <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
            </div>
          </section>
        </div>
        <section>
          <div className='container-fluid mt-4 p-5 devp'>
            <div className='row mt-4 py-4'>
              <div className='col-md-5 devh3'>
                <h3 className='text-justify'>Web Hosting Services </h3>
              </div>
              <div className='col-md-7 '>
                <p className='test'> Beangate provides fast, secure, and scalable web hosting solutions. With our
                  cutting-edge infrastructure and 24/7 technical support, we ensure optimal performance and uninterrupted
                  online presence for your website. Trust Beangate to handle your hosting needs, so you can focus on growing
                  your business with confidence. </p>{/* <p> We build websites that are agile, robust, and smart. Our
              cybersecurity, eCommerce, and wordpress solutions along with website architecture, maintenance, and custom
              CMS services are second to none. </p>*/}
              </div>
            </div>
          </div>
        </section>
        <section className='bg-dev'>
          <div className='container pt-5 pb-5 '>
            <div className='row'>
              <div className='col-md-12 '>
                <h3>Agile, Secure, and Smart Web Hosting Services </h3>{/* <h3>Web Design and Web Development Solutions</h3>
            */}
              </div>
              <div className='col-md-12 mt-5'>
                <p className='text-center '> Our high-performance servers, robust security measures, and round-the-clock
                  support ensure your website stays fast, secure, and always online. With seamless scalability and expert
                  management, Beangate empowers your online presence,. </p>
              </div>
            </div>
            <div className='row pt-5'>
              <div className='col-md-4 iconsize'>
                <div className='text-center'> <img src='icons/Shared Hosting.png'></img> </div>
                <h5 className='text-center mb-4'> Shared Hosting </h5>
                <p className='test'> Beangate's shared hosting service offers an affordable and user-friendly solution for
                  individuals and small businesses. Your website is hosted on a server shared with other users. </p>
              </div>
              <div className='col-md-4 iconsize'>
                <div className='text-center'> <img src='icons/VPS Hosting.png'></img> </div>
                <h5 className='text-center mb-4'> VPS Hosting</h5>
                <p className='test'> With Beangate's VPS (Virtual Private Server) hosting, you get a dedicated portion of
                  server resources, giving you more control, scalability, and enhanced performance compared to shared
                  hosting. </p>
              </div>
              <div className='col-md-4 iconsize'>
                <div className='text-center'> <img src='icons/Dedicated Server Hosting.png'></img> </div>
                <h5 className='text-center mb-4'> Dedicated Server Hosting</h5>
                <p className='test'> Beangate's dedicated server hosting provides the highest level of performance and
                  control. Your website resides on a dedicated server, ensuring exclusive access to resources and optimal
                  speed. </p>
              </div>
            </div>
            <div className='row' id=''>
              <div className='col-md-12 col-sm-6 text-center mt-4'>
                <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 pt-5 mt-3'> <img src='icons/webhostingleft.jpg'></img> </div>
              <div className='col-md-6 pt-5 devhjust '>
                <h3 className='text-justify pt-1 '> Robust Web Hosting with Smart Solutions </h3>
                <p>Beangate offers a wide range of web hosting solutions including:</p>
                <ul className='lignh'>
                  <li> <a href=''> Shared Hosting </a> </li>
                  <li> <a href=''> VPS Hosting </a> </li>
                  <li> <a href=''> Dedicated Server Hosting </a> </li>
                  <li> <a href=''> Cloud Hosting </a> </li>
                  <li> <a href=''> Managed WordPress Hosting </a> </li>
                  <li> <a href=''> Reseller Hosting </a> </li>
                  <li> <a href=''> eCommerce Hosting </a> </li>
                  <li> <a href=''> Windows Hosting </a> </li>
                  <li> <a href=''> Linux Hosting </a> </li>
                  <li> <a href=''> Enterprise Hosting </a> </li>
                  <li> <a href=''> Colocation Hosting </a> </li>
                  <li> <a href=''> Email Hosting </a> </li>
                  <li> <a href=''> CDN (Content Delivery Network) Hosting </a> </li>
                  <li> <a href=''> File Hosting </a> </li>
                  <li> <a href=''> Database Hosting </a> </li>
                  <p className='mt-4'> As India's most trusted web hosting company, Beangate earns reputation for its gamut
                    of bespoke services. </p>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-dev '>
          <div className='container pt-5 pb-5 '>
            <div className='row'>
              <div className='col-md-12 '>
                <h3>Technologies We Use for Web Design and Web Development </h3>{/* <h3>development solutions</h3> */}
              </div>
              <div className='col-md-12 mt-5'>
                <p className='text-center '> Choosing the right technologies is all it takes sometimes to make a business
                  but they say that it’s tricky.<br></br> No more! Take your business to new heights with our cutting-edge
                  technologies </p>
              </div>
            </div>
            <div className='row pt-5'>
              <div className='col-md-4 iconsize2'>
                <div className='text-center'> <img src='icons/app deve.png'></img> </div>
                <h5 className='text-center mb-4'>Frontend Development</h5>
                <p className='test'> We build surface layers of your web-based products using technologies such as React.js,
                  Vue.js, and Angular. .</p>
              </div>
              <div className='col-md-4 iconsize2'>
                <div className='text-center'> <img src='icons/collaboration.png'></img> </div>
                <h5 className='text-center mb-4'> Backend Development</h5>
                <p className='test'> We leverage the superpowers of Python, Ruby on Rails, and Node.js for prototyping and
                  development. </p>
              </div>
              <div className='col-md-4 iconsize2'>
                <div className='text-center'> <img src='icons/deployment.png'></img> </div>
                <h5 className='text-center mb-4'> Full Stack Development</h5>
                <p className='test'> We deliver end-to-end web development solutions using smart frontend and backend
                  solutions. </p>
              </div>
            </div>
            <div className='row' id=''>
              <div className='col-md-12 col-sm-6 text-center mt-4'>
                <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container mt-5 py-2 devp'>
            <div className='row mt-4 py-5'>
              <div className='col-md-5 devh4'>
                <h4 className='text-justify'>Leverage the superpowers of our web design and web development process for the
                  success of your business </h4>
              </div>
              <div className='col-md-7 '>
                <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web
                  solutions one must be detail-savvy. And, in a career spanning these many years and several products, we
                  know exactly how to handle projects of various magnitudes while also ensuring seamless process throughout.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container py-4 '>
            <div className='row'>
              <div className='col-md-1 col-sm-6 text-center'> </div>
              <div className='col-md-2 col-sm-6 text-center'>
                <p class="numbers__score h2 text-muted-light">1</p>
                <h4> Consultation </h4>
                <p class="numbers__caption">Brainstorm ideas to set the direction</p>
              </div>
              <div className='col-md-2 col-sm-6 text-center'>
                <p class="numbers__score h2 text-muted-light">2</p>
                <h4> Adoption </h4>
                <p class="numbers__caption">Choose a technology for the goals </p>
              </div>
              <div className='col-md-2 col-sm-6 text-center'>
                <p class="numbers__score h2 text-muted-light ">3</p>
                <h4> Design </h4>
                <p class="numbers__caption">Craft designs and give shapes to your visions </p>
              </div>
              <div className='col-md-2 col-sm-6 text-center'>
                <p class="numbers__score h2 text-muted-light">4</p>
                <h4>Develop </h4>
                <p class="numbers__caption">Start coding to bring your product to life</p>
              </div>
              <div className='col-md-2 col-sm-6 text-center'>
                <p class="numbers__score h2 text-muted-light">5</p>
                <h4> Reach </h4>
                <p class="numbers__caption">Launch the product to the market </p>
              </div>
              <div className='col-md-1 text-center'> </div>
            </div>
          </div>
        </section>
        <section className='bg-dev'>
          <div className='container py-5 mt-4 '>
            <div className='row'>
              <div className='col-md-3 col-sm-6 text-center'>
                <p class="numbers__score h2 numcol">70+</p>
                <p class="numbers__caption">Product Designers</p>
              </div>
              <div className='col-md-3 col-sm-6 text-center'>
                <p class="numbers__score h2 numcol">40+</p>
                <p class="numbers__caption">Frontend Developers</p>
              </div>
              <div className='col-md-3 col-sm-6 text-center'>
                <p class="numbers__score h2 numcol ">80+</p>
                <p class="numbers__caption">Backend Developers</p>
              </div>
              <div className='col-md-3 col-sm-6 text-center'>
                <p class="numbers__score h2 numcol">45+</p>
                <p class="numbers__caption">Quality Assurance Specialists</p>
              </div>
            </div>
            <div className='row py-4 mt-5'>
              <div className='col-md-5 pb-4 whtimg'> <img src='whybeangatedeve.png'></img> </div>
              <div className='col-md-7 pt-5 devhjust p-4'>
                <h3 className='text-justify pt-4 pb-4'> Why Choose Beangate as Your Web Hosting Company? </h3>
                <p className='mt-4 lh-lg test'> Choose Beangate as your web hosting company for unrivaled reliability,
                  blazing-fast performance, and exceptional customer support. Our cutting-edge infrastructure, advanced
                  security measures, and 24/7 technical assistance ensure your website remains secure, responsive, and
                  always online. With Beangate, experience the peace of mind that comes with reliable web hosting services
                  tailored to meet your specific needs. </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container'>
            <div className='row pt-5'>
              <div className='col-md-12 '>
                <h2>Our Web Hosting Company </h2>
              </div>
              <div className='col-md-12 mt-3'>
                <p className='text-center '> Beangate is helmed by a team of highly skilled professionals who are masters of
                  their craft. With a wealth of experience and expertise, we are adept at implementing performance. </p>
              </div>
            </div>
          </div>
        </section>


        <section className="container ">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loops={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              }
              
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Web Hosting Specialists.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Web Hosting Specialists </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Technical Support Managers.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Technical Support Managers </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Systems Administratorss.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Systems Administrators </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Network Engineers.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Network Engineers </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Security Analysts.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Security Analysts </h5>
                    <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                      the card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Customer Support Representatives.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Customer Support Representatives </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Server Operations Managers.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Server Operations Managers </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Cloud Hosting Architects.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Cloud Hosting Architects </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Data Center Technicians.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Data Center Technicians </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=''>
                <div class=" text-center">
                  <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                    src="icons/Web Hosting Consultants.jpg" alt="Card image cap" />
                    <h5 class="card-title p-3">Web Hosting Consultants </h5>
                    <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                      card's content.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        </section>





        <section className='bg-dev'>
          <div className='container-fluid pt-4'>
            <div className='row pt-5'>
              <h2 className='mb-3'> Frequently Asked Question </h2>
              <div className='col-md-5 text-center mt-5 pt-1 '> <img src='whatquestion2.png' ></img></div>
              <div className='col-md-7 '>
                <section id="why-us" class="why-us mt-5">
                  <div class="container-fluid" data-aos="fade-up">
                    <div class="row">
                      <div
                        class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                        <div class="accordion-list">
                          <ul>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                              class="collapsed"><span>01</span>Q: What is web hosting? <i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Web hosting is a service that allows individuals and businesses to make their
                                  websites accessible on the internet. It involves storing website files on servers
                                  connected to the internet, making them available for visitors to access. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                              class="collapsed"><span>02</span>Q: What types of web hosting services do you offer?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: We offer a range of web hosting services, including shared hosting, VPS hosting,
                                  dedicated server hosting, and cloud hosting. Each option offers different levels of
                                  performance, scalability, and control to suit various website needs.</p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                              class="collapsed"><span>03</span> Q: How do I choose the right web hosting plan?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: The right web hosting plan depends on factors such as your website's size, expected
                                  traffic, resource requirements, and budget. Our team can assess your needs and recommend
                                  the most suitable hosting plan for you. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                              class="collapsed"><span>04</span> Q: Can I upgrade my hosting plan in the future?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Absolutely! We understand that your website's needs may evolve over time. We provide
                                  easy upgrade options, allowing you to seamlessly transition to a higher-tier plan as your
                                  website grows. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                              class="collapsed"><span>05</span>Q: Is my website's data secure with your web hosting
                              service?<i class="bx bx-chevron-down icon-show"></i><i
                                class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Yes, we prioritize the security of your website and data. We implement robust
                                  security measures, including firewalls, malware scanning, and SSL certificates, to protect
                                  your website from potential threats. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                              class="collapsed"><span>06</span>Q: Do you offer backups for my website?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Yes, regular backups are an essential part of our web hosting service. We perform
                                  automated backups to ensure that your website's data is securely stored, and in the event
                                  of any issues, we can restore your website to a previous version. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                              class="collapsed"><span>07</span>Q: What level of technical support do you provide?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: We provide 24/7 technical support to assist you with any web hosting-related
                                  inquiries or issues. Our knowledgeable team is available via various channels, including
                                  live chat, email, and phone. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                              class="collapsed"><span>08</span>Q: Can I host multiple websites under one hosting
                              account?<i class="bx bx-chevron-down icon-show"></i><i
                                class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Depending on the hosting plan you choose, you can often host multiple websites under
                                  a single account. Our team can help you determine the most suitable plan for hosting
                                  multiple websites efficiently. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                              class="collapsed"><span>09</span> Q: Can I transfer my existing website from another hosting
                              provider to your service?<i class="bx bx-chevron-down icon-show"></i><i
                                class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Yes, we can assist you with transferring your website from another hosting provider
                                  to our service. Our migration specialists will ensure a smooth transition, minimizing any
                                  downtime during the process. </p>
                              </div>
                            </li>
                            <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                              class="collapsed"><span>10</span> Q: Are there any limitations on bandwidth and storage?<i
                                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                              <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
                                <p> A: Bandwidth and storage limitations vary depending on the hosting plan you choose. We
                                  offer plans with different resource allocations to accommodate a wide range of website
                                  needs. </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                        data-aos-delay="150">&nbsp;</div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <div className='contactmains'>
          <section>
            <section class="container " id="">
              <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
                chat...</h4>
              </header>
              <div class="row ">
                <div class="col-sm-6 contact-text" id="contact-text">
                  <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
                  <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                    Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                    with transformative solutions, enabling remarkable advancements and driving success at every stage of
                    the journey. </p>
                </div>
                <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
                  <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                    <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                      placeholder="Name" /> </div>
                    <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                      class="form-control" placeholder="Email" /> </div>
                    <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                      type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                        class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                          name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
                  </form>
                </div>
              </div>
            </section>
          </section>
          <section>
            <div className='container-fluid mt-5 mb-5'>
              <div className='row'>
                <div className='col-md-12'> </div>
              </div>
            </div>
          </section>
        </div>
      </div>




    </div>
  )
}