import React from 'react'
import Hero from '../components/Hero'
import Service from '../components/REDEFINED'
import Box3 from '../components/box3'
import Expertise from '../components/Expertise'
import Box7 from '../components/Box7'
import Box8 from '../components/Box8'
import Box10 from '../components/Box10'
import Box88 from '../components/box88'
import Sanjay from '../components/Sanjay'
import Team from '../components/Team'
import Box9 from '../components/Box9'
import Box81 from '../components/Box81'






export default function Home() {
  return (
    <div>
     
      <Hero />
      <Service></Service>
      <Box3></Box3>
      <Expertise></Expertise>
      <Box7></Box7>
      <Box9></Box9>
      <Box8></Box8>
      <Box81></Box81>
      {/* <Box88></Box88> */}
      <Box10></Box10>
      <Sanjay></Sanjay>
      <Team></Team>


    </div>
  )
}
