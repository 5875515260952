import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import "./digitalmarketing.css"

export default function Digitalmarketing() {
return (
<div>


  <div className=' ' id='devhero'>
    <div className='container-fluid mt-5'>
      <section class=" mt-5 digitalmarketingbanner " id="">
        <div class="jumbotrons digitalmarketingjumbo m-0 ">
          <h2 className='mt-5 mb-3'>Digital Mrketing </h2>
          <p> Rule the Digital Landscape with Result-Driven Marketing Solutions <br></br>Best Digital Marketing Company
            in India{/* <br></br> improve the way we serve you. */}</p>
          <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
        </div>
      </section>
    </div>
    <section>
      <div className='container-fluid mt-5 p-5 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh3'>
            <h3 className='text-justify'>Digital Marketing Services </h3>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Beangate is a leading digital marketing company focused on empowering businesses with
              innovative strategies and measurable results. With a dedicated team of experts, we offer a comprehensive
              range of services including SEO, PPC, social media marketing, content creation, and more. By combining
              cutting-edge techniques and a client-centric approach, we help our partners achieve their online goals and
              drive sustainable growth in the ever-evolving digital landscape. </p>{/* <p> We build websites that are
              agile, robust, and smart. Our cybersecurity, eCommerce, and wordpress solutions along with website
              architecture, maintenance, and custom CMS services are second to none. </p>*/}
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Optimize the Digital Presence of Your Business with No.1 Marketing Solutions </h3>{/* <h3>Web Design and
              Web Development Solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> From strategic search engine optimization (SEO) to targeted pay-per-click (PPC)
              advertising, we optimize your digital campaigns for maximum visibility and conversions. Our team
              specializes in social media marketing, content creation, and email marketing to engage your audience and
              build lasting relationships. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Search Engine Optimization.png'></img> </div>
            <h5 className='text-center mb-4'> Search Engine Optimization</h5>
            <p className='test'> Beangate delivers exceptional SEO services to boost your brand's online visibility,
              improve search rankings, and drive targeted traffic. Our expert team utilizes advanced strategies, keyword
              research, and optimized content to ensure long-term success in the competitive digital landscape. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Paid marketing services.png'></img> </div>
            <h5 className='text-center mb-4'> Paid marketing services </h5>
            <p className='test'> Beangate's paid marketing services drive growth by leveraging PPC and display
              advertising. With targeted campaigns, optimized ad placements, and continuous monitoring, we generate
              qualified leads and maximize ROI. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Social Media Markting.png'></img> </div>
            <h5 className='text-center mb-4'> Social Media Markting </h5>
            <p className='test'> Beangate's social media marketing services leverage popular platforms to engage your
              audience through captivating content, targeted ads, and community management. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 pt-5 mt-3'> <img src='icons/digitalmarketingleft.jpg'></img> </div>
          <div className='col-md-6 pt-5 devhjust '>
            <h3 className='text-justify pt-1 '> Unrivaled Digital Marketing Solutions Offered by Beangate IT Solutions
            </h3>
            <p> Beangate offers a wide range of digital marketing solutions including: </p>
            <ul className='lignh'>
              <li> <a href=''> One page optimization </a> </li>
              <li> <a href=''> Off page optimization </a> </li>
              <li> <a href=''> Technical SEO </a> </li>
              <li> <a href=''> Social media optimization </a> </li>
              <li> <a href=''> Pay per click </a> </li>
              <li> <a href='/Wordpress'> Facebook Ads </a> </li>
              <li> <a href=''> Instagram Ads </a> </li>
              <li> <a href=''> Google Ads </a> </li>{/* <li> <a href=''> Maintenance </a> </li>
              <li> <a href=''> Backend Solutions </a> </li>
              <li> <a href=''> Frontend Solutions </a> </li>*/}<p className='mt-4'> As India's leading digital marketing
                company, Beangate earns reputation for its gamut of bespoke marketing services. </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev '>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Technologies We Use for Web Design and Web Development </h3>{/* <h3>development solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Choosing the right technologies is all it takes sometimes to make a business
              but they say that it’s tricky.<br></br> No more! Take your business to new heights with our cutting-edge
              technologies </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/web hosting.png'></img> </div>
            <h5 className='text-center mb-4'>Frontend Development</h5>
            <p className='test'> We build surface layers of your web-based products using technologies such as React.js,
              Vue.js, and Angular. .</p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/android.png'></img> </div>
            <h5 className='text-center mb-4'> Backend Development</h5>
            <p className='test'> We leverage the superpowers of Python, Ruby on Rails, and Node.js for prototyping and
              development. </p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/cloud.png'></img> </div>
            <h5 className='text-center mb-4'> Full Stack Development</h5>
            <p className='test'> We deliver end-to-end web development solutions using smart frontend and backend
              solutions. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 py-2 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh4'>
            <h4 className='text-justify'>Leverage the superpowers of our web design and web development process for the
              success of your business </h4>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web
              solutions one must be detail-savvy. And, in a career spanning these many years and several products, we
              know exactly how to handle projects of various magnitudes while also ensuring seamless process throughout.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container py-4 '>
        <div className='row'>
          <div className='col-md-1 col-sm-6 text-center'> </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">1</p>
            <h4> Consultation </h4>
            <p class="numbers__caption">Brainstorm ideas to set the direction</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">2</p>
            <h4> Adoption </h4>
            <p class="numbers__caption">Choose a technology for the goals </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light ">3</p>
            <h4> Design </h4>
            <p class="numbers__caption">Craft designs and give shapes to your visions </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">4</p>
            <h4>Develop </h4>
            <p class="numbers__caption">Start coding to bring your product to life</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">5</p>
            <h4> Reach </h4>
            <p class="numbers__caption">Launch the product to the market </p>
          </div>
          <div className='col-md-1 text-center'> </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container py-5 mt-4 '>
        <div className='row'>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">70+</p>
            <p class="numbers__caption">Product Designers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">40+</p>
            <p class="numbers__caption">Frontend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol ">80+</p>
            <p class="numbers__caption">Backend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">45+</p>
            <p class="numbers__caption">Quality Assurance Specialists</p>
          </div>
        </div>
        <div className='row py-4 mt-3'>
          <div className='col-md-5  pb-4 whtimg'> <img src='whybeangatedeve.png'></img> </div>
          <div className='col-md-7 pt-5 devhjust p-4'>
            <h3 className='text-justify pt-3 mt-3 pb-4 '> Why Choose Beangate as Your Digital Marketing Company? </h3>
            <p className='mt-4 lh-lg test'> Choose Beangate as your Digital Marketing company for unmatched online
              visibility, seo, and reputation management. Our team excels in pay-per-click solutions. With our tailored
              solutions, you'll benefit from your website’s optimized performance. <br></br> </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 '>
            <h2>Our Digital Marketing Company </h2>
          </div>
          <div className='col-md-12 mt-3'>
            <p className='text-center '>Beangate is helmed by a team of highly skilled professionals who are masters of
              their craft. With a wealth of experience and expertise, our digital marketing experts are adept at
              implementing performance. </p>
          </div>
        </div>
      </div>
    </section>

    
    <section className="container">
   <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loops={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Social media specialists.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Social media specialists </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Content writers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Content writers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Web developers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Web developers </h5>
                <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                  the card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Graphic designers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Graphic designers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Performance experts.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Performance experts </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Growth marketers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Growth marketers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/UI Designers.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">UI Designers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
   </section>



    
    <section className='bg-dev'>
      <div className='container-fluid pt-4'>
        <div className='row pt-5'>
          <h2 className='mb-3'> Frequently Asked Question </h2>
          <div className='col-md-5 text-center '> <img src='whatquestion2.png'></img>{/* <div
              className='col-md-5 pt-5 pb-4 mt-3 whtimg'> </div>*/}</div>
          <div className='col-md-7 '>
            <section id="why-us" class="why-us mt-5">
              <div class="container-fluid" data-aos="fade-up">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                    <div class="accordion-list">
                      <ul>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                            class="collapsed"><span>01</span>Q: What digital marketing services does Beangate offer? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Beangate offers a comprehensive range of digital marketing services including search
                              engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing,
                              content creation, email marketing, conversion rate optimization (CRO), and more. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                            class="collapsed"><span>02</span>Q: How does Beangate optimize SEO?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Beangate employs advanced SEO techniques such as keyword research, on-page
                              optimization, link building, and technical optimization to improve website visibility,
                              organic rankings, and drive targeted traffic. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                            class="collapsed"><span>03</span> Q: What social media platforms does Beangate specialize
                            in?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Beangate specializes in social media marketing across various platforms including
                              Facebook, Instagram, Twitter, LinkedIn, and YouTube, tailoring strategies to each
                              platform's unique audience and features. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                            class="collapsed"><span>04</span> Q: How does Beangate create engaging content? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Beangate creates engaging content by conducting in-depth research on your target
                              audience, understanding their preferences, and crafting compelling and relevant content in
                              the form of articles, blog posts, videos, infographics, and more. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                            class="collapsed"><span>05</span>Q: How does Beangate measure the success of digital
                            marketing campaigns? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Beangate uses various tools and analytics to measure the success of digital marketing
                              campaigns, tracking key performance indicators (KPIs) such as website traffic,
                              conversions, engagement metrics, and ROI, providing comprehensive reports and insights for
                              continuous optimization. </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                    data-aos-delay="150">&nbsp;</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div className='contactmains'>
      <section>
        <section class="container " id="">
          <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
              chat...</h4>
          </header>
          <div class="row ">
            <div class="col-sm-6 contact-text" id="contact-text">
              <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
              <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                with transformative solutions, enabling remarkable advancements and driving success at every stage of
                the journey. </p>{/* <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email: </b><br></br>
                beangateITsolutions@gmail.com </p>*/}
            </div>
            <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
              <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                    placeholder="Name" /> </div>
                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                    class="form-control" placeholder="Email" /> </div>
                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                    type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                  class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                  name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className='container-fluid mt-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'> </div>
          </div>
        </div>
      </section>
    </div>
  </div>




</div>
)
}