import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import "./SoftwareDevelopment.css"

export default function SoftwareDevelopment() {
return (
<div>

  <div className=' ' id='devhero'>
    <div className='container-fluid mt-5'>
      <section class=" mt-5 Softwaredevelopment " id="">
        <div class="jumbotrons Softwaredevjumbo m-0 ">
          <h2 className='mt-5 mb-3'>Software Development </h2>
          <p> Introducing the Wave of Innovative Software Development Solutions for <br></br>Unparalleled Technologies
            <br></br>Top Software Development Company in India{/* <br></br> improve the way we serve you. */}</p>
          <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation</a></p>
        </div>
      </section>
    </div>
    <section>
      <div className='container-fluid mt-5 p-5 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-4 devh3'>
            <h3 className='text-justify'>Software Development Services </h3>
          </div>
          <div className='col-md-8 '>
            <p> Prepare to be dazzled as Beangate, the reigning wizard of software development services in India, waves
              its digital wand to enchant businesses across diverse industries. From IT and software to real estate,
              logistics, hospitality, and Edtech, our spellbinding expertise knows no bounds. Brace yourself for the
              creation of dynamic, secure, and nimble software applications that will elevate your business to new
              heights of efficiency and adaptability. With Beangate by your side, prepare to witness the magic unfold!
            </p>{/* <p> We build websites that are agile, robust, and smart. Our cybersecurity, eCommerce, and wordpress
              solutions along with website architecture, maintenance, and custom CMS services are second to none. </p>
            */}
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Ignite Your Business Rocket with Beangate's Software Sorcery </h3>{/* <h3>Web Design and Web Development
              Solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Step into the realm of extraordinary growth as Beangate, fueled by a fearless
              brigade of software development superheroes, leaps into action. We possess an unrivaled team of experts
              armed with the wisdom and prowess to craft high-performance software applications that will propel your
              business to the stratosphere of success. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/software dev.png'></img> </div>
            <h5 className='text-center mb-4'> Custom Software Development </h5>
            <p className='test'> Our skilled developers wield cutting-edge tools to create tailored software solutions
              that align perfectly with your business goals and needs. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Software Consulting.png'></img> </div>
            <h5 className='text-center mb-4'> Software Consulting</h5>
            <p className='test'> Our seasoned consultants provide strategic advice and insights to help you make
              informed decisions, optimize processes, and maximize the value of your software investments. </p>
          </div>
          <div className='col-md-4 iconsize'>
            <div className='text-center'> <img src='icons/Software Integration.png'></img> </div>
            <h5 className='text-center mb-4'> Software Integration</h5>
            <p className='test'> Our integration experts facilitate smooth data flow and seamless communication between
              your existing software systems, empowering your business with improved efficiency and productivity. </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 pt-5 mt-3'> <img src='icons/softwaredevelopmentleft.jpg'></img> </div>
          <div className='col-md-6 pt-5 devhjust '>
            <h3 className='text-justify pt-1 '> Level Up Your Game with Beangate's Genius Software Development Wizardry
              Take Your Digital Game a Notch Up with State-of-the-art Web Development Company </h3>
            <p> Beangate offers a wide range of solutions including:</p>
            <ul className='lignh'>
              <li> <a href=''> Custom Software Development: </a> </li>
              <li> <a href=''> Web Application Development: </a> </li>
              <li> <a href=''> Mobile App Development: </a> </li>
              <li> <a href=''> Software Consulting: </a> </li>
              <li> <a href=''> Cloud-Based Solutions: </a> </li>
              <li> <a href='/Wordpress'> E-commerce Development: </a> </li>
              <li> <a href=''> Enterprise Software Solutions </a> </li>
              <li> <a href=''> Quality Assurance and Testing </a> </li>
              <li> <a href=''> Software Integration </a> </li>
              <li> <a href=''> Maintenance and Support </a> </li>{/* <li> <a href=''> Frontend Solutions </a> </li>*/}<p
                className='mt-4 test'> As India's leading Softeare Development Company, we are committed to crafting
                bespoke software solutions that empower businesses to surpass their goals. We firmly believe that with
                our expertise and visionary approach, exceeding expectations becomes the new norm. </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-dev '>
      <div className='container pt-5 pb-5 '>
        <div className='row'>
          <div className='col-md-12 '>
            <h3>Harness the Superpowers of Our Android Development Process for Business Success </h3>{/* <h3>development
              solutions</h3> */}
          </div>
          <div className='col-md-12 mt-5'>
            <p className='text-center '> Led by a team of visionary Android development experts, Beangate understands
              the importance of meticulous attention to detail in delivering exceptional anroid development solutions.
              With years of experience and a portfolio of successful projects, we have mastered the art of handling
              projects of all sizes while maintaining a seamless development process from start to finish. </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/discoveryandroid.png'></img> </div>
            <h5 className='text-center mb-4'>Discovery Phase</h5>
            <p className='text-center'> Extensive research and analysis to ensure a solid foundation for the Android
              development process</p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/UXUI development.png'></img> </div>
            <h5 className='text-center mb-4'> UI/UX Design</h5>
            <p className='text-center'> Designing interfaces that are not only aesthetically pleasing but also easy to
              navigate </p>
          </div>
          <div className='col-md-4 iconsize2'>
            <div className='text-center'> <img src='icons/development.png'></img> </div>
            <h5 className='text-center mb-4'> Development</h5>
            <p className='text-center'> Industry best practices and coding standards to ensure clean and efficient code
              that is maintainable in the long run </p>
          </div>
        </div>
        <div className='row' id=''>
          <div className='col-md-12 col-sm-6 text-center mt-4'>
            <p> <a href="/ContactUs" className="btn-get-started ">Free Expert Consultation </a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 py-2 devp'>
        <div className='row mt-4 py-5'>
          <div className='col-md-5 devh4'>
            <h4 className='text-justify'>Leverage the superpowers of our web design and web development process for the
              success of your business </h4>
          </div>
          <div className='col-md-7 '>
            <p className='test'> Helmed by a team of new-age artisans, Beangate believes that to deliver spot on web
              solutions one must be detail-savvy. And, in a career spanning these many years and several products, we
              know exactly how to handle projects of various magnitudes while also ensuring seamless process throughout.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container py-4 '>
        <div className='row'>
          <div className='col-md-1 col-sm-6 text-center'> </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">1</p>
            <h4> Consultation </h4>
            <p class="numbers__caption">Brainstorm ideas to set the direction</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">2</p>
            <h4> Adoption </h4>
            <p class="numbers__caption">Choose a technology for the goals </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light ">3</p>
            <h4> Design </h4>
            <p class="numbers__caption">Craft designs and give shapes to your visions </p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">4</p>
            <h4>Develop </h4>
            <p class="numbers__caption">Start coding to bring your product to life</p>
          </div>
          <div className='col-md-2 col-sm-6 text-center'>
            <p class="numbers__score h2 text-muted-light">5</p>
            <h4> Reach </h4>
            <p class="numbers__caption">Launch the product to the market </p>
          </div>
          <div className='col-md-1 text-center'> </div>
        </div>
      </div>
    </section>
    <section className='bg-dev'>
      <div className='container py-5 mt-4 '>
        <div className='row'>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">70+</p>
            <p class="numbers__caption">Product Designers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">40+</p>
            <p class="numbers__caption">Frontend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol ">80+</p>
            <p class="numbers__caption">Backend Developers</p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <p class="numbers__score h2 numcol">45+</p>
            <p class="numbers__caption">Quality Assurance Specialists</p>
          </div>
        </div>
        <div className='row py-4 mt-5'>
          <div className='col-md-5  pb-2 whtimg '> <img src='whybeangatedeve.png'></img> </div>
          <div className='col-md-7 pt-5 devhjust p-4'>
            <h3 className='text-justify pt-3 pb-4 '> Why Choose Beangate as Your Software Development Company? </h3>
            <p className='mt-4 lh-lg test'> Choose Beangate as your software development company for an unparalleled
              experience. With our extensive expertise, tailored solutions, cutting-edge technologies, and commitment to
              quality, we deliver exceptional software applications that align with your unique business goals. Our
              seamless communication, timely delivery, and cost-effective approach ensure a smooth and successful
              project. We prioritize customer satisfaction, offering post-development support to keep your software
              up-to-date and running smoothly. Trust Beangate as your trusted partner and elevate your business to new
              heights with our unrivaled software development services. <br></br> </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 '>
            <h2>Our Software Development Company </h2>
          </div>
          <div className='col-md-12 mt-3'>
            <p className='text-center '>In the mystical depths of our organization lies a chosen fellowship of software
              programming sorcerers, wielding their magic to shape your product's destiny.<br></br> Brace yourself as we
              unveil our esteemed team of visionary virtuosos, ready to conjure unparalleled expertise at every step of
              your product's evolution. </p>
          </div>
        </div>
      </div>
    </section>
    <section className="container">
   <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loops={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/codemagicition.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Code Magicians</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/uxdesign.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">UX Designers</h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/frontend.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">User Experience (UX) Gurus </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Architect Extraordinaire.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Architect Extraordinaire </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Bug Busters.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Bug Busters </h5>
                <p class="card-text py-3 ">Some quick example text to build on the card title and make up the bulk of
                  the card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/agilecoach.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Agile Ninjas </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/securityexpert.jpg" alt="Card image cap" />
                <h5 class="card-title p-3"> Security Guardians </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/databasespecialist.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Data Whisperers </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Testing Virtuosos.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Testing Virtuosos </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/Innovation Catalysts.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Innovation Catalysts </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className=''>
            <div class=" text-center">
              <div class="card-body cardimg22 wid"> <img class="card-img-top center-block card-img-top1"
                  src="icons/projectmanager.jpg" alt="Card image cap" />
                <h5 class="card-title p-3">Project Maestros </h5>
                <p class="card-text py-3">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
   </section>
    
    
    <section className='bg-dev'>
      <div className='container-fluid pt-4'>
        <div className='row pt-5'>
          <h2 className='mb-3'> Frequently Asked Question </h2>
          <div className='col-md-5 text-center '> <img src='whatquestion2.png'></img>{/* <div
              className='col-md-5 pt-5 pb-4 mt-3 whtimg'> </div>*/}</div>
          <div className='col-md-7 '>
            <section id="why-us" class="why-us mt-5">
              <div class="container-fluid" data-aos="fade-up">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                    <div class="accordion-list">
                      <ul>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1"
                            class="collapsed"><span>01</span>Q: What is the typical timeline for software development
                            projects? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: The timeline for software development projects can vary depending on the complexity
                              and scope of the project. It is best to discuss your specific requirements with our team
                              to get a more accurate estimate of the timeline. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                            class="collapsed"><span>02</span>Q: How much does software development cost?<i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                            <p> The cost of software development depends on various factors, including project
                              complexity, features, and technologies involved. We offer customized solutions tailored to
                              your specific needs, and our team can provide you with a detailed cost estimate after
                              understanding your project requirements. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                            class="collapsed"><span>03</span> Q: Do you provide ongoing support and maintenance for the
                            software?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we offer comprehensive support and maintenance services for the software we
                              develop. Our team is dedicated to ensuring the smooth functioning of your software and
                              providing timely updates and assistance as needed. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                            class="collapsed"><span>04</span> Q: How do you ensure the security of the software and
                            data? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: We prioritize the security of your software and data. Our team follows industry best
                              practices and implements robust security measures to protect against potential
                              vulnerabilities and unauthorized access. We conduct thorough testing and employ encryption
                              techniques to safeguard your valuable information. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                            class="collapsed"><span>05</span>Q: Will our website be mobile-responsive? <i
                              class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Absolutely! We specialize in creating mobile-responsive websites, providing optimal
                              user experiences across various devices and screen sizes. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                            class="collapsed"><span>06</span> Q: Can you integrate the software with existing systems or
                            third-party applications? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we have expertise in integrating software with existing systems and third-party
                              applications. Our team can seamlessly connect your software with external services, APIs,
                              or databases, ensuring smooth data exchange and functionality. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                            class="collapsed"><span>07</span> Q: Will I have ownership of the software and its source
                            code? <i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                            <p> Absolutely. We believe in transparent and ethical practices. Upon project completion,
                              you will have full ownership of the software and its source code. We ensure that you have
                              the freedom to modify, enhance, or further develop the software as per your needs. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8"
                            class="collapsed"><span>08</span> Q: How do you handle changes or updates during the
                            software development process?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
                            <p> We follow an agile development approach, which allows for flexibility and accommodates
                              changes or updates during the software development process. Our team maintains clear
                              communication channels with clients and incorporates any necessary adjustments to ensure
                              that the final product meets your expectations. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9"
                            class="collapsed"><span>09</span> Q: Can you provide references or examples of your past
                            software development projects?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we can provide references and share examples of our past software development
                              projects. Our portfolio showcases a range of successful projects across various
                              industries. We encourage you to explore our work and client testimonials to gain
                              confidence in our capabilities. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10"
                            class="collapsed"><span>10</span> Q: Do you offer training or documentation for using the
                            software?<i class="bx bx-chevron-down icon-show"></i><i
                              class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Yes, we provide training and documentation to help you and your team effectively use
                              the software. We offer comprehensive user manuals, tutorials, and training sessions to
                              ensure a smooth transition and maximize the benefits of the software. </p>
                          </div>
                        </li>
                        <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-11"
                            class="collapsed"><span>11</span> Q: What is your approach to quality assurance and testing?
                            <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                          <div id="accordion-list-11" class="collapse" data-bs-parent=".accordion-list">
                            <p> A: Quality assurance is integral to our software development process. We follow rigorous
                              testing procedures, including functional testing, performance testing, and user acceptance
                              testing, to ensure the software meets the highest standards of quality, reliability, and
                              user satisfaction. </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in"
                    data-aos-delay="150">&nbsp;</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div className='contactmains'>
      <section>
        <section class="container " id="">
          <header>{/* <h2 className='text-center mt-5'>Contact US</h2> */}<h4 className='mt-5 mb-5 '>Let's have a
              chat...</h4>
          </header>
          <div class="row ">
            <div class="col-sm-6 contact-text" id="contact-text">
              <h3 className='mt-4'> Schedule a Free Expert Session with Beangate’s Head of Delivery</h3>
              <p className='px-4 border mt-5 mb-5 py-3'><br></br>Let’s Create a Better World! <br></br> <br></br>
                Regarded as the best IT Company in India, Beangate extends its support to empowering your businesses
                with transformative solutions, enabling remarkable advancements and driving success at every stage of
                the journey. </p>{/* <p className='px-4'><b>Fill the form or reach out to us at</b> <br></br>
                +91-7471112020<br></br> +91-9752740090<br></br> +0755-3100296<br></br> <b>Email: </b><br></br>
                beangateITsolutions@gmail.com </p>*/}
            </div>
            <div class="col-sm-6 bg-light p-5" id="contact-form bg-dev">
              <form action="mailto:biancamorris@hotmail.com" method="post" enctype="text/plain">
                <div class="form-group p-2 "> <label for="name">Name</label> <input type="name" class="form-control"
                    placeholder="Name" /> </div>
                <div class="form-group p-2 "> <label for="email">Email address</label> <input type="email"
                    class="form-control" placeholder="Email" /> </div>
                <div class="form-group p-2 "> <label for="comment">Comment</label> <textarea rows="5" cols="30"
                    type="comment" class="form-control" placeholder="Comment"></textarea> </div><button type="reset"
                  class="btn silver-button"><i class="fa fa-trash"></i> Reset</button> <button type="submit"
                  name="submit" class="btn purple-button" value="send"><i class="fa fa-send-o"></i> Submit</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className='container-fluid mt-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'> </div>
          </div>
        </div>
      </section>
    </div>
  </div>



</div>
)
}