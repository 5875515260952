import React from 'react'
import "./box3.css"

export default function Box3() {
return (
<div>
    <section class="box-3 ">
        <div className="section-title text-white light">
            <div className='pb-0'>
                <h2>Who We Are</h2>
            </div>
            <div className='lineh2'> </div>{/* <p>Unlock Opportunities and Revolutionize Productivity with No.1 IT
                Company in India.</p>*/}
        </div>
        <div class="main container">
            <div class="col-md-12 ">
                <div class="card-body text-justifycol">
                    <p className=''>Driven by a passion for technology and a relentless pursuit of excellence, Beangates is a
                        leading IT company in India committed to revolutionizing business landscapes through innovative
                        solutions. We harness cutting-edge technologies to help you thrive in the ever-evolving business
                        environment enabling you to make a difference by manifolds. </p><br />
                    <p> Helmed by a team of seasoned professionals who specialize in a wide range of IT solutions,
                        Beangates prides itself on being a trusted partner for resolving unique challenges of businesses
                        and offering them tailored end-to-end solutions that drive growth and enhance overall
                        productivity. </p>
                </div>
            </div>
        </div>
        <div class="button buttonbg"> <a href="#">Contact Us</a> </div>
    </section>
</div>
)
}