import React, { Component } from 'react'

export default class Sanjay extends Component {
render() {
return (
<>
  <section id="testimonials" class="testimonials mt-4">
    <div class="container" data-aos="fade-up">
      <div className="section-title text-white light">
        <div className=' mt-4'>
          <h2>What Our Clients Say</h2>
        </div>
        <div className='lineh66  mb-2'> </div>
        <p>We Are Transforming Business Through Strategic Partners By Delivering Compelling Experiance And Unparalleled
          Solutions</p>
      </div>
      <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
        <div class="swiper-wrapper text-white">
          <div class="swiper-slide">
            <div class="testimonial-wrap">
              <div class="testimonial-item"> <img src="assets/img/testimonials/rajkumar.jpg" class="testimonial-img"
                  alt="" />
                <h3>Dr. Rajkumar Malviya </h3>
                <h4>Ceo &amp; Founder of Arsdf</h4>
                <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Working with Beangate for our software
                  development needs was a game-changer for our business. Their team of experts delivered a customized
                  and robust software solution that perfectly catered to our requirements. Their professionalism,
                  attention to detail, and prompt communication made the entire development process seamless.
                   <i class="bx bxs-quote-alt-right quote-icon-right"></i> 
                   </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="testimonial-wrap">
              <div class="testimonial-item"> <img src="assets/img/testimonials/umashankar.jpg" class="testimonial-img"
                  alt="" />
                <h3>Umashankar Verma</h3>
                <h4>Owner & Director Of kesar construction</h4>
                <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Beangate took our digital marketing efforts to
                  the next level. Their strategic approach and in-depth understanding of our target audience helped us
                  achieve remarkable results. Their team implemented effective SEO techniques, created engaging content,
                  and managed our social media platforms with precision. We highly recommend the company. <i
                    class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="testimonial-wrap">
              <div class="testimonial-item"> <img src="assets/img/testimonials/chandraprakash.jpg"
                  class="testimonial-img" alt="" />
                <h3>Chandra Prakash Sharma</h3>
                <h4>Owner of EASS Security Pvt. Ltd.</h4>
                <p className='text-justify'> <i class="bx bxs-quote-alt-left quote-icon-left"></i>We were in need of a reliable and
                  user-friendly Android app, and Beangate delivered beyond our expectations. From conceptualization to
                  implementation,their team was proactive and highly skilled. They incorporated our ideas seamlessly
                  into the app and provided valuable insights to enhance the user experience.Thanks Beangate. <i
                    class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="testimonial-wrap">
              <div class="testimonial-item"> <img src="assets/img/testimonials/santosh.jpg" class="testimonial-img"
                  alt="" />
                <h3>Mr. Santosh Chaturvedi</h3>
                <h4>President of SSWA</h4>
                <p className='text-justify'> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Choosing Beangate for our website development
                  was the best decision we made for our online presence. Their team understood our vision and translated
                  it into a visually stunning and user-friendly website. They ensured that the site was optimized for
                  performance, responsive on all devices, and integrated with essential features Highly recommended. <i
                    class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</>
)
}
}